import * as React from 'react';
import { keyBy, chain } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@blueprintjs/core';

import BasicTable from 'reports/components/core/tables/BasicTable';

import * as scen from 'reports/models/scenario';
import * as mod from 'reports/models/module';
import * as pd from 'reports/models/power_device';

import * as auth from 'reports/modules/auth';
import { IAppState } from 'reports/types';

interface Props {
    scenario: scen.Scenario;
}

export const CharacterizationTable = ({ scenario }: Props) => {
    const [loading, setLoading] = React.useState(true);
    const [projDeviceCharacterizations, setProjDeviceCharacterizations] = React.useState<
        pd.PowerDeviceCharacterization[] | undefined
    >(undefined);
    const [projModuleCharacterizations, setProjModuleCharacterizations] = React.useState<
        mod.ModuleCharacterization[] | undefined
    >(undefined);
    const [sortedPDCs, setSortedPDCs] = React.useState<pd.PowerDeviceCharacterization[]>([]);
    const [sortedMCs, setSortedMCs] = React.useState<mod.ModuleCharacterization[]>([]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const loadProjectCharacterizations = async () => {
            setLoading(true);
            setProjDeviceCharacterizations(undefined);
            setProjModuleCharacterizations(undefined);

            const [projModuleCharacterizations, projDeviceCharacterizations] = await Promise.all([
                dispatch(mod.charApi.index({ project_id: scenario.project_id })),
                dispatch(pd.charApi.index({ project_id: scenario.project_id })),
            ]);

            setProjDeviceCharacterizations(projDeviceCharacterizations);
            setProjModuleCharacterizations(projModuleCharacterizations);
            setLoading(false);
        };

        loadProjectCharacterizations();
    }, [scenario.project_id]);

    React.useEffect(() => {
        if (projModuleCharacterizations === undefined || projDeviceCharacterizations === undefined) {
            return;
        }

        const scenMCs = keyBy(
            scenario.module_characterizations.map((smc) => smc.module_characterization),
            'module_id',
        );

        const scenPDCs = keyBy(
            scenario.power_device_characterizations.map((spdc) => spdc.power_device_characterization),
            'power_device_id',
        );

        setSortedMCs(
            chain(projModuleCharacterizations)
                .map('module')
                .uniq()
                .map((mod) => scenMCs[mod.module_id] || mod.defaultCharacterization())
                .sortBy(['module.manufacturer', 'module.name'])
                .value(),
        );
        setSortedPDCs(
            chain(projDeviceCharacterizations)
                .map('power_device')
                .uniq()
                .map((pd) => scenPDCs[pd.power_device_id] || pd.defaultCharacterization())
                .sortBy(['power_device.device_type_name', 'power_device.manufacturer', 'power_device.name'])
                .value(),
        );
    }, [projModuleCharacterizations, projDeviceCharacterizations, scenario]);

    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const bifacialEnabled = user.hasFeature('enable_bifacial');

    return (
        <BasicTable>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Component</th>
                    <th>Characterization</th>
                    <th>Uploaded By</th>
                    <th>Description</th>
                    {bifacialEnabled && <th>Bifacial</th>}
                </tr>
            </thead>
            <tbody>
                {loading && (
                    <tr>
                        <td colSpan={5}>
                            <Spinner />
                        </td>
                    </tr>
                )}
                {sortedMCs.map((mc) => (
                    <tr key={mc.module_characterization_id}>
                        <td>Module</td>
                        <td>
                            {mc.module.name} ({mc.module.manufacturer})
                        </td>
                        <td>{mc.name}</td>
                        <td>{mc.module.team!.name}</td>
                        <td>{mc.description}</td>
                        {bifacialEnabled && <td>{mc.is_bifacial ? 'True' : 'False'}</td>}
                    </tr>
                ))}
                {sortedPDCs.map((pdc) => (
                    <tr key={pdc.power_device_characterization_id}>
                        <td>{pd.PowerDeviceTypes[pdc.power_device.device_type_name]}</td>
                        <td>
                            {pdc.power_device.name} ({pdc.power_device.manufacturer})
                        </td>
                        <td>{pdc.name}</td>
                        <td>{pdc.power_device.team!.name}</td>
                        <td>{pdc.description}</td>
                        {bifacialEnabled && <td>N/A</td>}
                    </tr>
                ))}
            </tbody>
        </BasicTable>
    );
};

export default CharacterizationTable;
