import * as React from 'react';
import { noop } from 'lodash';
import { Flex } from 'reports/components/core/containers';
import { WeatherDataset } from 'reports/models/weather_dataset';
import { WeatherSource } from 'reports/models/weather_source';
import { Scenario, AnnualVariabilityType } from 'reports/models/scenario';
import { FormPercentInput } from 'reports/components/forms/inputs/experimental';
import { FormField } from 'reports/components/forms';
import { VariabilityTableWrapper, DisplayMode, SystemVariability } from './ConditionSetP90Edit';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StyledResetButton = styled.div`
    color: #2d72d2;

    &&:hover {
        cursor: pointer;
        text-decoration: underline;
    }
`;

const StyledContainer = styled(Flex.Container)`
    gap: 8px;
    justify-content: center;
    align-items: baseline;

    & > div:first-child {
        margin: 0px;
        flex-grow: 1;
    }
`;

const LabelTd = styled.td`
    width: 100px;
    white-space: nowrap;
`;

type IWeatherVariabilityTableProps = {
    scenario: Scenario;
    displayMode: DisplayMode;
    currentWeatherDataset?: WeatherDataset;
    currentWeatherSource?: WeatherSource;
    recalculateTotalVariability?: (updatedAnnualWeatherVariability: number) => void;
    formValue?: SystemVariability;
};

interface IAnnualVariabilityFieldProps {
    currentWeatherDataset?: WeatherDataset;
    currentWeatherSource?: WeatherSource;
    formValue: number;
    onVariabilityChange: (updatedVariability: number) => void;
    onVariabilityTypeChange: (updatedType: AnnualVariabilityType) => void;
    recalculateTotalVariability: (updatedAnnualWeatherVariability: number) => void;
}

const getTypeAndVariabilityForDataset = (
    currentWeatherDataset?: WeatherDataset,
    currentWeatherSource?: WeatherSource,
) => {
    let variability;
    let variabilityType: AnnualVariabilityType | undefined;

    if (currentWeatherDataset && currentWeatherSource) {
        const { estimated_ghi_year_to_year_variability } = currentWeatherDataset;
        const { source_type, ghi_year_to_year_variability } = currentWeatherSource;
        variability = 0;
        variabilityType = 'custom';

        if (estimated_ghi_year_to_year_variability) {
            variability = estimated_ghi_year_to_year_variability;
            variabilityType = 'estimated';
        }

        if (source_type === 'meteonorm_v8' && ghi_year_to_year_variability !== undefined) {
            variability = ghi_year_to_year_variability;
            variabilityType = 'meteonorm';
        }
    }

    return { variability, variabilityType };
};

const AnnualVariabilityField = ({
    onVariabilityChange: onChange,
    formValue,
    currentWeatherDataset,
    currentWeatherSource,
    onVariabilityTypeChange,
    recalculateTotalVariability,
}: IAnnualVariabilityFieldProps) => {
    const isFirstRender = React.useRef(true);
    const [initialFormValue, setInitialFormValue] = React.useState(formValue);

    const updateFormValueAndType = (value: number, type: AnnualVariabilityType) => {
        onChange(value);
        onVariabilityTypeChange(type);
        setInitialFormValue(value);
    };

    const setVariabilityAndTypeFromSource = React.useCallback(() => {
        const { variability, variabilityType } = getTypeAndVariabilityForDataset(
            currentWeatherDataset,
            currentWeatherSource,
        );

        if (variability && variabilityType) {
            updateFormValueAndType(variability, variabilityType);
        }
    }, [currentWeatherDataset, currentWeatherSource]);

    React.useEffect(() => {
        // set the value from the source on the first render if condition set is missing variability
        if (isFirstRender.current && formValue === null) {
            setVariabilityAndTypeFromSource();
        }

        // only update from source change if it's not the first render
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        setVariabilityAndTypeFromSource();
    }, [currentWeatherDataset?.weather_dataset_id]);

    React.useEffect(() => {
        const { variability } = getTypeAndVariabilityForDataset(currentWeatherDataset, currentWeatherSource);

        if (!!formValue && !!initialFormValue && formValue.toFixed(2) !== initialFormValue.toFixed(2)) {
            onVariabilityTypeChange('custom');
        } else if (formValue !== initialFormValue && initialFormValue === variability) {
            setVariabilityAndTypeFromSource();
        }

        recalculateTotalVariability(formValue);
    }, [formValue]);

    return (
        <>
            <FormPercentInput
                data-testid="annual_weather_variability"
                path="annual_weather_variability"
                placeholder="Annual Variability %"
            />
            <StyledResetButton onClick={setVariabilityAndTypeFromSource}>Reset Value</StyledResetButton>
        </>
    );
};

interface IAnnualVariabilityTypeFieldProps {
    currentWeatherDataset?: WeatherDataset;
    currentWeatherSource?: WeatherSource;
    onVariabilityTypeChange: (updatedType: AnnualVariabilityType) => void;
    recalculateTotalVariability: (updatedAnnualWeatherVariability: number) => void;
}

const WeatherVariabilityTypeForm = ({
    onVariabilityTypeChange,
    currentWeatherDataset,
    currentWeatherSource,
    recalculateTotalVariability,
}: IAnnualVariabilityTypeFieldProps) => {
    return (
        <FormField path="annual_weather_variability">
            {({ onChange, value }) => (
                <AnnualVariabilityField
                    onVariabilityChange={onChange}
                    formValue={value}
                    currentWeatherDataset={currentWeatherDataset}
                    currentWeatherSource={currentWeatherSource}
                    onVariabilityTypeChange={onVariabilityTypeChange}
                    recalculateTotalVariability={recalculateTotalVariability}
                />
            )}
        </FormField>
    );
};

export const WeatherVariabilityTable = ({
    scenario,
    displayMode,
    currentWeatherSource,
    currentWeatherDataset,
    recalculateTotalVariability,
}: IWeatherVariabilityTableProps) => {
    const [currentVariabilityType, setCurrentVariabilityType] = React.useState<AnnualVariabilityType>(
        scenario.annual_weather_variability_type || 'custom',
    );

    let weatherSourceLabel =
        displayMode === 'view' ? scenario.weather_dataset.weather_source.toString() : currentWeatherSource?.toString();

    weatherSourceLabel =
        currentVariabilityType === 'custom' ? 'User-defined' : `${weatherSourceLabel} - ${currentVariabilityType}`;

    const handleWeatherVariabilityTypeChange = (updatedType: AnnualVariabilityType) => {
        setCurrentVariabilityType(updatedType);
    };

    const annualWeatherVariability = scenario.annual_weather_variability || 0;

    return (
        <VariabilityTableWrapper title="Weather Variability">
            <tr>
                <LabelTd>Datasource</LabelTd>
                <td style={{ textAlign: 'left' }} data-testid="data-source-cell">
                    {weatherSourceLabel}
                </td>
            </tr>
            <tr>
                <LabelTd>Annual Variability</LabelTd>
                <td>
                    {displayMode === 'view' ? (
                        <div style={{ textAlign: 'left' }} data-testid="annual-variability-value">
                            {annualWeatherVariability.toFixed(2)}%
                        </div>
                    ) : (
                        <StyledContainer>
                            <FormField path="annual_weather_variability_type">
                                {({ onChange }) => (
                                    <WeatherVariabilityTypeForm
                                        onVariabilityTypeChange={(updatedType) => {
                                            handleWeatherVariabilityTypeChange(updatedType);
                                            onChange(updatedType);
                                        }}
                                        currentWeatherDataset={currentWeatherDataset}
                                        currentWeatherSource={currentWeatherSource}
                                        recalculateTotalVariability={recalculateTotalVariability || noop}
                                    />
                                )}
                            </FormField>
                        </StyledContainer>
                    )}
                </td>
            </tr>
        </VariabilityTableWrapper>
    );
};
