import * as React from 'react';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Button } from 'reports/components/core/controls';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';
import { InlineFormGroup } from 'reports/components/helpers/formHelpers';

interface IOwnProps<ResourceCharacterization> {
    characterizations: ResourceCharacterization[];
    selectedChar: ResourceCharacterization;
    setSelectedChar: (char: ResourceCharacterization) => void;
    isDefaultChar: (char: ResourceCharacterization) => boolean;
    makeDefaultChar: (char: ResourceCharacterization) => void;
    charId: (char: ResourceCharacterization) => number;
}

const CharacterizationSelect = <ResourceCharacterization extends { name: string }>({
    characterizations,
    selectedChar,
    setSelectedChar,
    isDefaultChar,
    charId,
}) => (
    <InlineFormGroup label="Characterization">
        <BasicSelect
            dataSource={{ items: characterizations }}
            itemRenderer={(char: ResourceCharacterization) => ({
                text: char.name,
                key: charId(char),
                labelElement: isDefaultChar(char) ? <Icon icon={IconNames.PIN} /> : undefined,
            })}
            value={selectedChar}
            onChange={setSelectedChar}
            disabled={characterizations.length < 2}
        />
    </InlineFormGroup>
);

const CharacterizationViewControls = <ResourceCharacterization extends { name: string }>(
    props: IOwnProps<ResourceCharacterization>,
) => {
    const { selectedChar, characterizations, isDefaultChar, makeDefaultChar, setSelectedChar, charId } = props;
    const isDefaultSelected = isDefaultChar(selectedChar);

    return (
        <>
            <CharacterizationSelect<ResourceCharacterization>
                characterizations={characterizations}
                selectedChar={selectedChar}
                setSelectedChar={setSelectedChar}
                isDefaultChar={isDefaultChar}
                charId={charId}
            />
            <Button
                disabled={isDefaultSelected}
                active={isDefaultSelected}
                icon={IconNames.PIN}
                onClick={(e) => {
                    e.stopPropagation();
                    makeDefaultChar(selectedChar);
                }}
                style={{ marginLeft: 10 }}
            >
                {isDefaultSelected ? 'Current Default' : 'Make Default'}
            </Button>
        </>
    );
};

export default CharacterizationViewControls;
