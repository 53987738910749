import React from 'react';
import { Section2 } from 'reports/components/core/containers';
import ConditionSetMonthlyValuesEdit from './ConditionSetMonthlyValuesEdit';
import { DEFAULT_SCENARIO_PARAMS } from 'reports/models/scenario';

export const SOILING_MONTHS = [
    {
        path: 'jan_soiling',
        monthName: 'Jan',
    },
    {
        path: 'feb_soiling',
        monthName: 'Feb',
    },
    {
        path: 'mar_soiling',
        monthName: 'Mar',
    },
    {
        path: 'apr_soiling',
        monthName: 'Apr',
    },
    {
        path: 'may_soiling',
        monthName: 'May',
    },
    {
        path: 'jun_soiling',
        monthName: 'Jun',
    },
    {
        path: 'jul_soiling',
        monthName: 'Jul',
    },
    {
        path: 'aug_soiling',
        monthName: 'Aug',
    },
    {
        path: 'sep_soiling',
        monthName: 'Sep',
    },
    {
        path: 'oct_soiling',
        monthName: 'Oct',
    },
    {
        path: 'nov_soiling',
        monthName: 'Nov',
    },
    {
        path: 'dec_soiling',
        monthName: 'Dec',
    },
];

const ConditionSetSoilingEdit = () => (
    <Section2 title="Soiling" subtitle="Derates to irradiance due to module soiling or other factors">
        <ConditionSetMonthlyValuesEdit
            fieldMap={SOILING_MONTHS}
            defaultAllValue={DEFAULT_SCENARIO_PARAMS.default_soiling}
        />
    </Section2>
);

export default ConditionSetSoilingEdit;
