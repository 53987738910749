/* tslint:disable:variable-name function-name */
import { Intent } from '@blueprintjs/core';
import type { Address, Metadata } from '@stripe/stripe-js';
import { omit } from 'lodash';
import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

import { schema } from './schema';
import * as ext from './subscription_extension';
import * as prc from './stripe/price';
import * as su from './subscription_user';
import * as tm from './team';
import type { User } from './user';
import { Card } from './stripe/payment_method';
import type { BillingV2MigrationLog } from './billing_v2_migration_log';
import { StripeSubscription } from './stripe/stripe_subscription';
import type { Invoice } from './stripe';

enum SubscriptionStatuses {
    initialized = 'Awaiting Payment',
    active = 'Active',
    canceled = 'Subscription expired',
    canceled_pending = 'Canceling after current period',
    canceled_extended = 'Canceled and has subscription extension',
    unpaid = 'Suspended account',
    incomplete = 'Pending',
    incomplete_expired = 'Expired',
    past_due = 'Past Due',
}

type SubscriptionStatus = keyof typeof SubscriptionStatuses;

enum ScheduledChanges {
    remove_seats = 'remove_seats',
    cancel_at_period_end = 'cancel_at_period_end',
    plan_downgrade = 'plan_downgrade',
}

enum Interval {
    quarterly = 'quarterly',
    biannual = 'biannual',
    annual = 'annual',
}

enum InvoiceTerm {
    fifteen = 15,
    thirty = 30,
    fortyfive = 45,
    sixty = 60,
    ninety = 90,
}

type PaymentFailureInfo = {
    latest_invoice_url: string;
    has_3DS_failure: boolean;
};

type ScheduledChange = keyof typeof ScheduledChanges;

export const getSubscriptionStatusIntent = (status: SubscriptionStatus) => {
    switch (status) {
        case 'initialized':
        case 'canceled_pending':
        case 'incomplete':
            return Intent.WARNING;
        case 'active':
            return Intent.SUCCESS;
        default:
            return Intent.DANGER;
    }
};

class Subscription extends BaseClass {
    external_id: string;
    stripe_id: string;
    subscription_type: 'contract' | 'self_serve';
    plan_type: prc.RecurringInterval;
    plan_name: string;
    product_key: string;

    is_contract: boolean;
    is_self_serve: boolean;

    collection_method: string;
    is_pay_by_invoice: boolean;

    creator_id: number;
    creator: User;
    created: moment.Moment;

    status: SubscriptionStatus;
    current_period_start: moment.Moment;
    current_period_end: moment.Moment;
    cleaned_status: SubscriptionStatus;
    has_upcoming_invoice: boolean;
    is_active: boolean;
    is_closed: boolean;
    is_expired: boolean;
    is_extended: boolean;

    extension_end_date?: moment.Moment;
    subscription_extension?: ext.SubscriptionExtension;
    subscription_extension_id?: number;

    test_clock_frozen_time?: moment.Moment;

    paid_seats: number;
    payment_intent_client_secret?: string;

    subscription_users: su.SubscriptionUser[];

    team_id: number;
    team: tm.Team;

    used_seats: number;

    migrated_from_v1_at?: moment.Moment;
    billing_v2_migration_log?: BillingV2MigrationLog;

    stripe_data: StripeSubscription;
    price: prc.Price;
    latest_invoice?: Invoice;
    card: Card;
    scheduled_changes: ScheduledChange | null;
    has_failed_payment: boolean;
    payment_failure_info: PaymentFailureInfo;
    is_awaiting_payment: boolean;

    // ------------- Attributes below this line are for Contract Subscriptions only -------------
    interval: string;
    invoice_term_days: number;
    project_limit: number;
    total_contract_value: number;
    enable_self_serve: boolean;

    // ------------- Methods for all subscription versions -------------
    constructor(data) {
        super(Subscription.deserializer(omit(data, 'subscribers')));
    }

    static deserializer = BaseClass.getDeserializer({
        card: (card) => new Card(card),
        created: (x) => moment(x),
        current_period_start: (x) => moment(x),
        current_period_end: (x) => moment(x),
        extension_end_date: (x) => moment(x),
        migrated_from_v1_at: (x) => moment(x),
        test_clock_frozen_time: (x) => moment(x),
        stripe_data: (x) => new StripeSubscription(x),
    });

    get subscribers() {
        return this.subscription_users.map((sub_user) => sub_user.user);
    }

    get product() {
        return this.product_key || 'basic';
    }

    get remainingSeats() {
        return this.paid_seats - this.subscribers.length;
    }

    get metadata(): Metadata | null {
        if (this.stripe_data == null) {
            return null;
        }
        return this.stripe_data.metadata;
    }

    get selfServeDisabled(): boolean {
        return this.is_contract && this.status === 'canceled' && !this.enable_self_serve;
    }

    now = () => this.test_clock_frozen_time || moment();
}

export interface NewSubscriptionForm {
    stripe_price: string;
    quantity: number;
    address: Address;
    email: string;
    name: string;
    purchase_order?: string;
    collection_method: string;
    tou_accepted?: boolean;
}

export interface ContractSubscriptionForm {
    interval: string | null;
    invoice_term_days: number | null;
    paid_seats: number;
    project_limit?: number;
    total_contract_value?: number;
    current_period_start: moment.Moment;
    current_period_end: moment.Moment;
    enable_self_serve?: boolean;
}

export type InviteMemberForm = {
    email: string;
    first_name: string;
    last_name: string;
};

export type ResendInviteForm = {
    user: number;
};

export type RescindInviteForm = {
    user: number;
};

export type UpdateSeatForm = {
    user: number;
};

export interface IPersonalDataForm {
    first_name: string;
    last_name: string;
    email: string;
}

interface BillingInfoForm {
    purchase_order?: string;
    email?: string;
    name?: string;
    address?: Address;
}

export interface AddLicensesForm {
    payment_method_id?: string;
    total_desired_seats: number;
    collection_method: string;
    billing_info?: BillingInfoForm;
}

export interface RemoveLicensesForm {
    total_desired_seats: number;
}

export interface UpgradeSubscriptionForm {
    prorate_timestamp?: string;
    payment_method_id?: string;
    price_id: string;
    collection_method: string;
    billing_info?: BillingInfoForm;
}

export interface DowngradeSubscriptionForm {
    price_id: string;
}

const schemaObj = schema.addObject(Subscription, 'subscription', {
    relationships: {
        team: { schema: tm.schemaObj },
        price: { schema: prc.schemaObj },
        subscription_extension: { schema: ext.schemaObj },
    },
    idName: 'external_id',
});
su.schemaObj.addRelationship('subscription', schemaObj, { idName: 'external_id', backref: 'subscription_users' });

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

const endpoint = ReduxEndpoint.fromSchema('/api/v2/subscriptions/', schemaObj);
const contractSubscriptionEndpoint = ReduxEndpoint.fromSchema('/api/contract_subscriptions/', schemaObj);

const api = {
    addLicenses: endpoint.post<AddLicensesForm, { external_id: string }>('{external_id}/add_licenses'),
    removeLicenses: endpoint.post<RemoveLicensesForm, { external_id: string }>('{external_id}/remove_licenses'),
    upgrade: endpoint.put<UpgradeSubscriptionForm, { external_id: string }>('{external_id}/upgrade'),
    downgrade: endpoint.put<DowngradeSubscriptionForm, { external_id: string }>('{external_id}/downgrade'),
    get: endpoint.get<{ external_id: string }>('{external_id}'),
    create: endpoint.post<NewSubscriptionForm>(),
    // Warning: this may charge the user. For example, updating CC after a failed payment will trigger a charge for
    // the overdue payment.
    cancelAtPeriodEnd: endpoint.post<{ external_id: string }>('{external_id}/cancel_at_period_end'),
    delete: endpoint.delete('{external_id}'),
    refreshCache: endpoint.post<{ external_id: string }>('{external_id}/refresh_cache'),
    cancelScheduledChanges: endpoint.post<{ external_id: string }>('{external_id}/cancel_scheduled_changes'),
    // These subscriber APIs could be simplified by changing them to POST and DELETE /subscription_users. Then
    // ReduxEndpoint would provide built-in support for deletion.
    inviteMember: endpoint.post<InviteMemberForm, { external_id: string }>('{external_id}/invite_member'),
    resendInvite: endpoint.post<ResendInviteForm, { external_id: string }>('{external_id}/resend_invite', {
        ...ReduxEndpoint.PassThroughConfig(),
    }),
    rescindInvite: endpoint.post<RescindInviteForm, { external_id: string }>('{external_id}/rescind_invite', {
        onSuccess: (_data, body: UpdateSeatForm, params) => (dispatch) => {
            dispatch(su.schemaObj.entityDeleted({ external_id: params.external_id, user_id: body.user }));
        },
    }),
    assignSeat: endpoint.post<UpdateSeatForm, { external_id: string }>('{external_id}/assign_seat'),
    removeSeat: endpoint.post<UpdateSeatForm, { external_id: string }>('{external_id}/remove_seat', {
        onSuccess: (_data, body: UpdateSeatForm, params) => (dispatch) => {
            dispatch(su.schemaObj.entityDeleted({ external_id: params.external_id, user_id: body.user }));
        },
    }),
};

const contractSubscriptionApi = {
    create: contractSubscriptionEndpoint.post<ContractSubscriptionForm, { email?: string }>(),
    save: contractSubscriptionEndpoint.put<ContractSubscriptionForm | { external_id: string }, { email?: string }>(
        '{external_id}',
    ),
};

export {
    Interval,
    InvoiceTerm,
    Subscription,
    SubscriptionStatuses,
    ScheduledChanges,
    schemaObj,
    api,
    contractSubscriptionApi,
    selectors,
};
