import { get, keyBy } from 'lodash';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { FormattedMessage } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';
import * as auth from 'reports/modules/auth';
import * as fmt from 'reports/utils/formatters';
import { MONTHS_INITIAL } from 'reports/utils/constants';

import { IWidgetRenderProps, IReportContext, registerWidget } from 'reports/modules/report/widgets';
import { WidgetDataTable, WidgetDetailsTable } from 'reports/modules/report/components/helpers';
import { FormattedNumber, Percent, Temperature } from 'reports/components/core/numbers';

import { ModuleCharacterization } from 'reports/models/module';
import * as pd from 'reports/models/power_device';
import { Design } from 'reports/models/design';
import * as scen from 'reports/models/scenario';

const SandiaTemperatureModelTable = ({ parameters }: { parameters: scen.ISandiaCellTempParameters[] }) => {
    return (
        <WidgetDataTable>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.design.rack_type} />
                    </th>
                    <th>a</th>
                    <th>b</th>
                    <th>
                        <FormattedMessage {...Translations.conditions.temperature_delta} />
                    </th>
                </tr>
            </thead>
            <tbody>
                {parameters.map((param, idx) => (
                    <tr key={idx}>
                        <td>{fmt.rackingType(param.rack_type)}</td>
                        <td>
                            <FormattedNumber value={param.a} />
                        </td>
                        <td>
                            <FormattedNumber value={param.b} />
                        </td>
                        <td>
                            <Temperature value={param.delta_temperature} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </WidgetDataTable>
    );
};

const DiffuseTemperatureModelTable = ({ parameters }: { parameters: scen.IDiffuseCellTempParameters[] }) => {
    return (
        <WidgetDataTable>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.design.rack_type} />
                    </th>
                    <th>
                        U<sub>const</sub>
                    </th>
                    <th>
                        U<sub>wind</sub>
                    </th>
                </tr>
            </thead>
            <tbody>
                {parameters.map((param, idx) => (
                    <tr key={idx}>
                        <td>{fmt.rackingType(param.rack_type)}</td>
                        <td>
                            <FormattedNumber value={param.u_const} />
                        </td>
                        <td>
                            <FormattedNumber value={param.u_wind} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </WidgetDataTable>
    );
};

const TemperatureModelTable = ({ scenario }: { scenario: scen.Scenario }) => {
    if (scenario.cell_temp_parameters != null) {
        if (scenario.cell_temp_model === 'sandia') {
            return (
                <SandiaTemperatureModelTable
                    parameters={scenario.cell_temp_parameters as scen.ISandiaCellTempParameters[]}
                />
            );
        }
        return (
            <DiffuseTemperatureModelTable
                parameters={scenario.cell_temp_parameters as scen.IDiffuseCellTempParameters[]}
            />
        );
    }
    return <></>;
};

const SoilingTable = ({ scenario }: { scenario: scen.Scenario }) => {
    return (
        <WidgetDataTable>
            <thead>
                <tr>
                    {MONTHS_INITIAL.map((month, idx) => (
                        <th key={idx}>
                            <FormattedMessage {...month} />
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                <tr>
                    {scenario.soiling.map((soiling, i) => (
                        <td key={i}>{soiling}</td>
                    ))}
                </tr>
            </tbody>
        </WidgetDataTable>
    );
};

interface CharacterizationsTableProps {
    moduleChars: ModuleCharacterization[];
    deviceChars: pd.PowerDeviceCharacterization[];
    design: Design;
    bifacialModelEnabled: boolean;
}

const CharacterizationsTable = ({
    moduleChars,
    deviceChars,
    design,
    bifacialModelEnabled,
}: CharacterizationsTableProps) => {
    const fsMap = {};
    if (design) {
        design.field_segments
            .filter((fs) => fs.module_characterization != null)
            .forEach((fs) => {
                if (!fsMap[fs.module_characterization.module_id]) {
                    fsMap[fs.module_characterization.module_id] = [];
                }

                fsMap[fs.module_characterization.module_id].push(fs);
            });
    }
    const warnings = (moduleCharacterization) => {
        const fsArray = fsMap[moduleCharacterization.module_id];
        let warnDims = false;
        let warnPower = false;
        fsArray.forEach((fs) => {
            warnDims = warnDims || fs.module_characterization.length !== moduleCharacterization.length;
            warnDims = warnDims || fs.module_characterization.width !== moduleCharacterization.width;
            warnPower = warnPower || fs.module_characterization.power !== moduleCharacterization.power;
        });
        return (
            (warnDims ? '[WARNING: Dimensions differ from characterization used in design]' : '') +
            (warnPower ? '[WARNING: Power differs from characterization used in design]' : '')
        );
    };

    return (
        <WidgetDataTable>
            <thead>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.design.component_type} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.component} />
                    </th>
                    <th>
                        <FormattedMessage {...Translations.design.characterization} />
                    </th>
                    {bifacialModelEnabled && (
                        <th>
                            <FormattedMessage {...Translations.design.bifacial} />
                        </th>
                    )}
                </tr>
            </thead>
            <tbody>
                {moduleChars.map((characterization, idx) => (
                    <tr key={idx}>
                        <td>
                            <FormattedMessage {...Translations.design.module} />
                        </td>
                        <td>
                            {characterization.module.name} ({characterization.module.manufacturer})
                        </td>
                        <td>
                            {warnings(characterization)} {characterization.name},{' '}
                            {fmt.moduleModel(characterization.module_model_name)}
                        </td>
                        {bifacialModelEnabled && <td>{characterization.is_bifacial ? 'True' : 'False'}</td>}
                    </tr>
                ))}
                {deviceChars.map((characterization, idx) => (
                    <tr key={idx}>
                        <td>{pd.PowerDeviceTypes[characterization.power_device.device_type_name]}</td>
                        <td>
                            {characterization.power_device.name}&nbsp; ({characterization.power_device.manufacturer})
                        </td>
                        <td>{characterization.name}</td>
                        {bifacialModelEnabled && (
                            <td>
                                <FormattedMessage {...Translations.design.not_applicable} />
                            </td>
                        )}
                    </tr>
                ))}
            </tbody>
        </WidgetDataTable>
    );
};

type IContext = Pick<IReportContext, 'design' | 'scenario'>;

const ConditionSetTable = ({ context, className }: IWidgetRenderProps<object, IContext>) => {
    const { design, scenario } = context;

    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const scenModuleCharacterizations = useSelector((state) => scen.selectors.moduleCharacterizations(state, scenario));
    const scenPowerDeviceCharacterizations = useSelector((state) =>
        scen.selectors.powerDeviceCharacterizations(state, scenario),
    );

    let moduleCharacterizations: ModuleCharacterization[] = [];
    let powerDeviceCharacterizations: pd.PowerDeviceCharacterization[] = [];

    if (design != null) {
        const usedModules = design.field_segments
            .filter((fs) => fs.module_characterization != null)
            .map((fs) => fs.module_characterization.module_id);

        const indexedModuleCharacterizations = keyBy(scenModuleCharacterizations, 'module_id');

        moduleCharacterizations = usedModules
            .map((id) => indexedModuleCharacterizations[id]?.module_characterization)
            .filter((mc) => mc != null)
            .sort((a, b) =>
                (a.module.manufacturer + a.module.name).localeCompare(b.module.manufacturer + b.module.name),
            );

        const usedPowerDevices = design.wiring_zones
            .map((wz) => wz.inverter_id)
            .concat(design.wiring_zones.map((wz) => wz.power_optimizer_id))
            .filter(Boolean);

        const indexedDeviceCharacterizations = keyBy(scenPowerDeviceCharacterizations, 'power_device_id');

        powerDeviceCharacterizations = usedPowerDevices
            .map((id) => indexedDeviceCharacterizations[id]?.power_device_characterization)
            .filter((pdc) => pdc != null)
            .sort((a, b) =>
                (a.power_device.manufacturer + a.power_device.name).localeCompare(
                    b.power_device.manufacturer + b.power_device.name,
                ),
            );
    }
    const weatherDataset = get(scenario, 'weather_dataset');
    const weatherDatasetContent =
        weatherDataset != null ? (
            <>
                <span>
                    {weatherDataset.name}, {weatherDataset.weather_source.name}, {weatherDataset.weather_source.source}(
                    {weatherDataset.weather_source.source_type})&nbsp;
                </span>
                {weatherDataset.get_url != null && (
                    <span>
                        (<a href={weatherDataset.get_url}>download</a>)
                    </span>
                )}
            </>
        ) : (
            '-'
        );

    return (
        <WidgetDetailsTable className={className}>
            <colgroup>
                <col style={{ width: '35%' }} />
                <col />
            </colgroup>
            <tbody>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.general.description} />
                    </th>
                    <td>{scenario.description}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.weather_dataset} />
                    </th>
                    <td>{weatherDatasetContent}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.solar_angle_location} />
                    </th>
                    <td>
                        {scenario.use_project_location ? (
                            <FormattedMessage {...Translations.project.project_lat_lng} />
                        ) : (
                            <FormattedMessage {...Translations.conditions.meteo_lat_lng} />
                        )}
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.transposition_model} />
                    </th>
                    <td>{fmt.transpositionModel(scenario.transposition_model)}</td>
                </tr>
                {scenario.sketchup_model != null && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.conditions.near_shade_profile} />
                        </th>
                        <td>{scenario.sketchup_model.name}</td>
                    </tr>
                )}
                {scenario.horizon != null && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.conditions.horizon_profile} />
                        </th>
                        <td>{scenario.horizon.description}</td>
                    </tr>
                )}
                {scenario.hasSpectral() && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.conditions.spectral_adjustment_model} />
                        </th>
                        <td>
                            {weatherDataset != null
                                ? fmt.spectralAdjustmentModel(weatherDataset.spectral_analysis_source_type)
                                : '-'}
                        </td>
                    </tr>
                )}
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.temperature_model} />
                    </th>
                    <td>{fmt.cellTemperatureModel(scenario.cell_temp_model)}</td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.temperature_model_parameters} />
                    </th>
                    <td className="table-cell">
                        <TemperatureModelTable scenario={scenario} />
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.soiling} /> (%)
                    </th>
                    <td className="table-cell">
                        <SoilingTable scenario={scenario} />
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.irradiation_variance} />
                    </th>
                    <td>
                        <Percent value={scenario.irradiation_variance / 100} />
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.cell_temp_spread} />
                    </th>
                    <td>
                        <Temperature value={scenario.temperature_variance} />
                    </td>
                </tr>
                <tr>
                    <th>
                        <FormattedMessage {...Translations.conditions.module_binning_range} />
                    </th>
                    <td>
                        <Percent value={scenario.min_module_binning / 100} />
                        {' to '}
                        <Percent value={scenario.max_module_binning / 100} />
                    </td>
                </tr>
                {scenario.ac_conductor_derate != null && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.conditions.ac_derate} />
                        </th>
                        <td>
                            <Percent value={scenario.ac_conductor_derate / 100} precision={2} />
                        </td>
                    </tr>
                )}
                {user.hasSingleAxisTrackersAccess() && (
                    <tr>
                        <th>
                            <FormattedMessage {...Translations.conditions.tracker_parameters} />
                        </th>
                        <td className="table-cell">
                            <WidgetDataTable>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage {...Translations.conditions.tracker_max_angle} />
                                        </th>
                                        <td>{scenario.tracker_max_angle}&deg;</td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <FormattedMessage {...Translations.conditions.tracker_backtrack} />
                                        </th>
                                        <td>{scenario.tracker_backtrack ? 'On' : 'Off'}</td>
                                    </tr>
                                </thead>
                            </WidgetDataTable>
                        </td>
                    </tr>
                )}
                <tr>
                    <th>
                        <FormattedMessage {...Translations.design.component_characterizations} />
                    </th>
                    <td className="table-cell">
                        <CharacterizationsTable
                            moduleChars={moduleCharacterizations}
                            deviceChars={powerDeviceCharacterizations}
                            design={design}
                            bifacialModelEnabled={!!scenario.bifacial_model_enabled}
                        />
                    </td>
                </tr>
            </tbody>
        </WidgetDetailsTable>
    );
};

export const ConditionSetTableWidget = registerWidget('condition_set_table', {
    Component: ConditionSetTable,
    metadata: {
        category: 'project',
        dimensions: { h: 750, w: 800 },
        displayName: Translations.widgets.condition_set_table_header,
        icon: IconNames.TH,
    },
    dependencies: ['design', 'scenario'],
});

export default ConditionSetTableWidget;
