import * as React from 'react';
import { clamp, isFinite, isNil, isNumber, isString } from 'lodash';
import { NumericInput as BpNumericInput, Classes, Position } from '@blueprintjs/core';

import { SimpleFunctionComponent } from './common';
import * as fmt from 'reports/utils/formatters';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const BLANK_VALUE = '—';

const parseAndValidate = (valueStr: string, min: number = -Infinity, max: number = Infinity, integerOnly: boolean) => {
    if (valueStr === '' || isNil(valueStr)) {
        return null;
    }

    const num = integerOnly ? fmt.numberStringToInt(valueStr) : fmt.numberStringToFloat(valueStr);

    if (!isFinite(num)) {
        return null;
    }

    return clamp(num, min, max);
};

const toEditValue = (value: number | undefined | null) => {
    return isNil(value) ? '' : String(value);
};

const toFormattedValue = (value: number | null, precision?: number) => {
    return isNil(value) ? BLANK_VALUE : fmt.stringifyNumberSimple(value, precision);
};

interface INumericInputProps {
    value: number | null;
    placeholder?: string;
    onChange: (val: number | null) => void;
    disabled?: boolean;
    precision?: number;
    width?: string | number;
    min?: number;
    max?: number;
    stepSize?: number;
    minorStepSize?: number | null;
    integerOnly?: boolean;
    updateOnKeyDown?: boolean;
}

/**
 * An input for a numeric decimal or integer field.
 *
 * @param value {number | null} The raw, unformatted number value to display in the input
 * @param placeholder {string} The placeholder value displayed in the input
 * @param onChange {function} Called when the user updates the value
 * @param disabled {boolean} When true, disables and greys out the input
 * @param precision {number} How many digits of precision to show after the decimal.
 *                           This only affects the formatting of the field, not the raw value.
 * @param width {string | number} A number in pixels or a valid CSS string value such as "100%"
 *                                to apply to the input element
 * @param min {number} The minimum valid value. The value is clamped.
 *                     E.g. if the user types "-1" and the minimum is 0, 0 will be set as the value.
 * @param max {number} The maximum valid value. The value is clamped.
 *                     E.g. if the user types "10" and the maximum is 8, 8 will be set as the value.
 * @param stepSize {number} The step size for this value, using the built in controls or the arrow keys
 * @param minorStepSize {number} The minor step size for this value, using the Alt clicking the built in controls or Alt + arrow keys
 * @param integerOnly {boolean} If true, disallows "." from being typed and constrains values to integers.
 *                              When true, the step up/down buttons are disabled.
 * @param updateOnKeyDown {boolean} If true, onChange is called on every value change (key down, paste),
 *                                  rather than only on blur or enter.
 */
const _NumericInput = (props: INumericInputProps & { className: string }) => {
    const {
        value,
        placeholder,
        onChange,
        disabled = false,
        precision = undefined,
        min = undefined,
        max = undefined,
        stepSize,
        minorStepSize,
        integerOnly = false,
        updateOnKeyDown = false,
        className,
    } = props;

    const [editValue, setEditValue] = React.useState<string>(toEditValue(value));
    const [isEditMode, setEditMode] = React.useState<boolean>(false);

    React.useEffect(() => {
        // Handles updates to value that are made outside of this component (e.g. via the form's values changing)
        if (!isNil(value) && editValue === '') {
            setEditValue(toEditValue(value));
        }
    }, [value]);

    return (
        <BpNumericInput
            // className is needed for styled-components
            className={className}
            placeholder={placeholder}
            value={isEditMode ? editValue : toFormattedValue(value, precision)}
            stepSize={stepSize}
            minorStepSize={minorStepSize}
            min={min}
            max={max}
            onKeyDown={(evt) => {
                switch (evt.key) {
                    case 'Enter':
                        evt.currentTarget.blur();
                        break;
                    case 'Escape':
                        setEditMode(false);
                        break;
                    case '.':
                        if (integerOnly) {
                            evt.preventDefault();
                        }
                        break;
                }
            }}
            onValueChange={(_valueAsNumber, valueAsString) => {
                setEditValue(valueAsString);
                // !isEditMode handles up/down buttons
                if (!isEditMode || updateOnKeyDown) {
                    onChange(parseAndValidate(valueAsString, min, max, integerOnly));
                }
            }}
            disabled={disabled}
            selectAllOnFocus={true}
            onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                const val = (e.target as HTMLInputElement).value;

                // We can't use clampValueOnBlur because there's no way to
                // distinguish the clamp update from regular user input
                const newVal = parseAndValidate(val, min, max, integerOnly);
                onChange(newVal);
                setEditValue(isNil(newVal) ? '' : String(newVal));
                setEditMode(false);
            }}
            onFocus={() => {
                setEditMode(true);
            }}
            buttonPosition={integerOnly ? Position.RIGHT : 'none'}
        />
    );
};

const NumericInput: SimpleFunctionComponent<INumericInputProps> = styled(_NumericInput)`
    &&& .${Classes.INPUT_GROUP} {
        ${({ width }) => (isNumber(width) ? `width: ${width}px;` : '')};
        ${({ width }) => (isString(width) ? `width: ${width};` : '')};
    }
`;

export default NumericInput;
