import * as React from 'react';
import { isEqual } from 'lodash';
import { Flex, Section2 } from 'reports/components/core/containers';
import { FormCheckboxInput, FormBareSwitchInput } from 'reports/components/forms/inputs/experimental';
import * as scen from 'reports/models/scenario';
import * as styles from 'reports/styles/styled-components';
import { FormField } from 'reports/components/forms';
import BasicTable from 'reports/components/core/tables/BasicTable';
import { Icon, Intent, Position } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Tooltip } from 'reports/components/core/controls';
import { WeatherDataset } from 'reports/models/weather_dataset';
import { WeatherSource } from 'reports/models/weather_source';
import { SystemVariabilityTable } from './SystemVariability';
import { WeatherVariabilityTable } from './WeatherVariability';
import Callout, { BottomMargin } from 'reports/components/core/controls/Callout';

export const PROBABILITY_DISTRIBUTION_HELP_URL =
    'https://help-center.helioscope.com/hc/en-us/articles/8307116250003-Creating-a-P50-and-P90-with-HelioScope';

const SAFE_WEATHER_DATASET_NAMES = ['TMY', 'TDY', 'TGY'];

const styled = styles.styled;

const StyledLink = styled.a`
    && :hover {
        text-decoration: underline;
    }
`;

const FormSection = styled.div<{ disabled?: boolean }>`
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const StyledSection = styled(Section2)`
    & > .header {
        border-bottom: 0px;
        margin-top: 16px;
    }

    & > .body {
        padding: 0px;
    }
`;

const ProbabilityValueDiv = styled.div`
    & > * {
        margin: 0px;
    }
`;

const HelperText = styled.div<{ bold?: boolean; large?: boolean; small?: boolean }>`
    color: #5c7080;
    font-size: 12px;
    font-size: ${({ small }) => (small ? '10px' : '')};
    font-size: ${({ large }) => (large ? '14px' : '')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

const ControlLabel = styled.div`
    max-width: 550px;
`;

const ControlPrimaryText = styled.div`
    font-weight: normal;
`;

const ControlContainer = styled.div`
    text-align: right;
`;

const CheckboxContainer = styled.div`
    display: flex;
    flex-direction: column;

    > div {
        margin-bottom: 0px;

        label {
            margin-bottom: 0px !important;
        }
    }
`;

const StyledBasicTable = styled(BasicTable)`
    height: fit-content;
`;

const StyledVariabilityTableContainer = styled(Flex.Container)`
    gap: 16px;
`;

const StyledTotalVariabilityContainer = styled(Flex.Container)`
    margin-top: 16px;
`;

const StyledTotalVariabilityInfoIcon = styled(Icon)`
    margin-left: 4px;
    margin-right: 16px;
`;

export type IConditionSetP90EditProps = {
    scenario: scen.Scenario;
    currentWeatherDataset?: WeatherDataset;
    currentWeatherSource?: WeatherSource;
};

export type DisplayMode = 'edit' | 'view';

export type SystemVariability = {
    pv_module_modeling_parameters: number;
    degradation_estimation: number;
    inverter_efficiency: number;
    soiling_mismatch: number;
    custom_variability: number;
};

export type ProbabilityDistributionConfig = {
    system_variability: SystemVariability;
    total_variability: number;
    is_enabled: boolean;
    probability_values: {
        p90: boolean;
        p95: boolean;
        p99: boolean;
    };
};

export const calculateTotalVariability = (
    annualWeatherVariability: number,
    systemVariabilityParams: SystemVariability,
): number => {
    const {
        pv_module_modeling_parameters,
        degradation_estimation,
        inverter_efficiency,
        soiling_mismatch,
        custom_variability,
    } = systemVariabilityParams;

    return Math.hypot(
        annualWeatherVariability || 0,
        pv_module_modeling_parameters || 0,
        degradation_estimation || 0,
        inverter_efficiency || 0,
        soiling_mismatch || 0,
        custom_variability || 0,
    );
};

export const P90SectionSubtitle = (
    <>
        Provide insights into the reliability and variability of your solar system's performance. For more information
        about this feature visit our{' '}
        <StyledLink href={PROBABILITY_DISTRIBUTION_HELP_URL} target="_blank">
            Help Center
        </StyledLink>
        .
    </>
);

export const VariabilityTableWrapper = ({ title, children }) => {
    return (
        <StyledBasicTable width="100%">
            <thead>
                <tr>
                    <th style={{ textAlign: 'center' }} colSpan={2}>
                        {title}
                    </th>
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </StyledBasicTable>
    );
};

interface IWeatherDataCompatibilityWarningProps {
    isWeatherDataCompatible: boolean;
}

const WeatherDataCompatibilityWarning = ({ isWeatherDataCompatible }: IWeatherDataCompatibilityWarningProps) => {
    if (isWeatherDataCompatible) {
        return null;
    }

    return (
        <Callout bottomMargin={BottomMargin.STANDARD} icon={IconNames.WARNING_SIGN} intent={Intent.WARNING}>
            <Flex.Container>
                Probability values not available for single-year weather datasets. To enable this feature, please change
                your weather source.
            </Flex.Container>
        </Callout>
    );
};

type InternalConditionSetP90EditProps = IConditionSetP90EditProps & {
    updateProbDistConfig: (config: ProbabilityDistributionConfig) => void;
    probDistConfigFormValue: ProbabilityDistributionConfig;
};

const ConditionSetP90Edit = ({
    scenario,
    currentWeatherDataset,
    currentWeatherSource,
    updateProbDistConfig,
    probDistConfigFormValue,
}: InternalConditionSetP90EditProps) => {
    const [probDistConfig, setProbDistConfig] = React.useState<ProbabilityDistributionConfig>({
        ...scen.DEFAULT_SCENARIO_PARAMS.probability_distribution_config,
        ...scenario.probability_distribution_config,
    });

    const [annualWeatherVariability, setAnnualWeatherVariability] = React.useState<number>(
        scenario.annual_weather_variability,
    );

    const [isWeatherDataCompatible, setIsWeatherDataCompatible] = React.useState<boolean>(true);

    const disableProbDist = React.useCallback(() => {
        updateProbDistConfig({
            ...probDistConfig,
            is_enabled: false,
        });
    }, [updateProbDistConfig, probDistConfig]);

    React.useEffect(() => {
        if (currentWeatherDataset?.name && SAFE_WEATHER_DATASET_NAMES.includes(currentWeatherDataset.name)) {
            setIsWeatherDataCompatible(true);
            return;
        }
        setIsWeatherDataCompatible(false);
        disableProbDist();
    }, [currentWeatherDataset?.name]);

    // Callback to pass down to AnnualWeatherVariability form so it can trigger recalculations of the
    // total_variability
    const recalculateTotalVariability = React.useCallback(
        (updatedAnnualWeatherVariability: number) => {
            const updatedProbDistConfig: ProbabilityDistributionConfig = {
                ...probDistConfig,
                total_variability: calculateTotalVariability(
                    updatedAnnualWeatherVariability,
                    probDistConfig.system_variability,
                ),
            };
            updateProbDistConfig(updatedProbDistConfig);
            setAnnualWeatherVariability(updatedAnnualWeatherVariability);
        },
        [probDistConfig, updateProbDistConfig],
    );

    // This is required to monitor state of the form so we can recalculate the total_variability on change
    // Unfortunately FormField doesn't provide a simple, direct method for getting current state or hooking
    // into changes as they happen.
    React.useEffect(() => {
        if (isEqual(probDistConfigFormValue, probDistConfig)) {
            return;
        }

        const updatedProbDistConfig: ProbabilityDistributionConfig = {
            ...probDistConfig,
            ...probDistConfigFormValue,
        };

        updatedProbDistConfig.total_variability = calculateTotalVariability(
            annualWeatherVariability,
            updatedProbDistConfig.system_variability,
        );

        updateProbDistConfig(updatedProbDistConfig);
        setProbDistConfig(updatedProbDistConfig);
    }, [probDistConfigFormValue]);

    return (
        <Section2 title="Probability Distribution" subtitle={P90SectionSubtitle} id="p90">
            <WeatherDataCompatibilityWarning isWeatherDataCompatible={isWeatherDataCompatible} />
            <FormSection data-testid="form-section-P90" disabled={!isWeatherDataCompatible}>
                <Flex.Container>
                    <ControlContainer data-testid="is-enable-switch">
                        <FormBareSwitchInput path="probability_distribution_config.is_enabled" />
                    </ControlContainer>
                    <ControlLabel>
                        <ControlPrimaryText>Enable Probability Distribution</ControlPrimaryText>
                        <HelperText>
                            Enable this feature to calculate and display probability-based production values.
                        </HelperText>
                    </ControlLabel>
                </Flex.Container>
            </FormSection>

            <FormSection
                data-testid="form-section-prob-config"
                disabled={!probDistConfig.is_enabled || !isWeatherDataCompatible}
            >
                <StyledSection title="Probability Values">
                    <HelperText>
                        Choose probability values you would like to calculate and display on the Production Report.
                    </HelperText>
                    <CheckboxContainer>
                        <ProbabilityValueDiv>
                            <FormCheckboxInput
                                path="probability_distribution_config.probability_values.p90"
                                checkboxLabel="P90"
                            />
                        </ProbabilityValueDiv>
                        <ProbabilityValueDiv>
                            <FormCheckboxInput
                                path="probability_distribution_config.probability_values.p95"
                                checkboxLabel="P95"
                            />
                        </ProbabilityValueDiv>
                        <ProbabilityValueDiv>
                            <FormCheckboxInput
                                path="probability_distribution_config.probability_values.p99"
                                checkboxLabel="P99"
                            />
                        </ProbabilityValueDiv>
                    </CheckboxContainer>
                </StyledSection>

                <StyledVariabilityTableContainer>
                    <WeatherVariabilityTable
                        scenario={scenario}
                        displayMode="edit"
                        recalculateTotalVariability={recalculateTotalVariability}
                        currentWeatherDataset={currentWeatherDataset}
                        currentWeatherSource={currentWeatherSource}
                    />
                    <SystemVariabilityTable scenario={scenario} displayMode="edit" />
                </StyledVariabilityTableContainer>

                <StyledTotalVariabilityContainer>
                    <div>
                        Resulting Weather & System Variability
                        <Tooltip
                            content="This value is calculated through taking a sigma of weather variability and system variability."
                            position={Position.TOP}
                            hoverCloseDelay={2000}
                        >
                            <StyledTotalVariabilityInfoIcon icon={IconNames.INFO_SIGN} intent={Intent.PRIMARY} />
                        </Tooltip>
                        <FormField path="probability_distribution_config.total_variability">
                            {({ value }) => {
                                return (
                                    <strong data-testid="total-variability-value">
                                        {' '}
                                        {value !== undefined ? value.toFixed(2) : 0.0}%
                                    </strong>
                                );
                            }}
                        </FormField>
                    </div>
                </StyledTotalVariabilityContainer>
            </FormSection>
        </Section2>
    );
};

const ConditionSetP90EditWrapper = ({
    scenario,
    currentWeatherDataset,
    currentWeatherSource,
}: IConditionSetP90EditProps) => {
    return (
        <FormField path="probability_distribution_config">
            {({ value: probDistConfigFormValue, onChange: updateProbDistConfig }) => (
                <ConditionSetP90Edit
                    scenario={scenario}
                    updateProbDistConfig={updateProbDistConfig}
                    probDistConfigFormValue={probDistConfigFormValue}
                    currentWeatherSource={currentWeatherSource}
                    currentWeatherDataset={currentWeatherDataset}
                />
            )}
        </FormField>
    );
};

export default ConditionSetP90EditWrapper;
