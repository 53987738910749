import { range } from 'lodash';
import Highcharts from 'highcharts';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { injectIntl, IntlShape } from 'react-intl';

import { IAppState } from 'reports/types';

import Translations from 'reports/localization/strings';
import * as fmt from 'reports/utils/formatters';

import * as auth from 'reports/modules/auth';
import * as uc from 'reports/models/user_consumption';
import { Project, selectors as projSelectors } from 'reports/models/project';
import { Theme } from 'reports/models/theme';

import { selectors as finSelectors } from 'reports/modules/financials';
import { DEFAULT_THEME } from 'reports/modules/themes';

import { ILayoutChartProps, LayoutChart, ChartType } from 'reports/modules/report/components/charts';

interface Props extends ILayoutChartProps {
    project: Project;
    consumptionData?: uc.UserConsumption;

    chartType?: ChartType;
    consumption?: boolean;
    production?: boolean;

    title?: string;
    legend?: boolean;

    theme?: Theme;
}

interface PropsIntl extends Props {
    intl: IntlShape;
}

interface ISeries {
    name: string;
    data: number[];
    type: string;
    color: any;
    visible?: boolean;
}

function makeConfig(series: ISeries[], intl: IntlShape, title?: string, legend?: boolean): Highcharts.Options {
    const { locale } = intl;
    return {
        series,

        chart: {
            type: 'area',
            backgroundColor: 'transparent',
        },
        title: {
            text: title || '',
        },
        xAxis: {
            categories: [
                intl.formatMessage(Translations.constants.january_abbrev),
                intl.formatMessage(Translations.constants.february_abbrev),
                intl.formatMessage(Translations.constants.march_abbrev),
                intl.formatMessage(Translations.constants.april_abbrev),
                intl.formatMessage(Translations.constants.may_abbrev),
                intl.formatMessage(Translations.constants.june_abbrev),
                intl.formatMessage(Translations.constants.july_abbrev),
                intl.formatMessage(Translations.constants.august_abbrev),
                intl.formatMessage(Translations.constants.september_abbrev),
                intl.formatMessage(Translations.constants.october_abbrev),
                intl.formatMessage(Translations.constants.november_abbrev),
                intl.formatMessage(Translations.constants.december_abbrev),
            ],
        },
        yAxis: {
            min: 0,
            title: {
                text: 'kWh',
            },
            labels: {
                formatter(this: any) {
                    return fmt.stringifyNumber(this.value, {
                        locale,
                        // @ts-ignore: notation option exists, but is not in our version of typescript.
                        notation: 'compact',
                    });
                },
            },
            visible: true,
        },
        legend: {
            enabled: legend !== undefined ? legend : true,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                // y-axis is in kWh. multiply by 1000 to convert to Wh.
                return `${this.x}: <b>${fmt.humanizeEnergy(this.y * 1000, {
                    locale,
                    precision: 1,
                })}</b>`;
            },
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            series: {
                animation: false,
            },
        },
    };
}

const mapStateToProps = () => {
    const showConsumptionSelector = createSelector(
        (state) => {
            const user = auth.selectors.getUser(state)!;

            return user.hasFinancialsAccess();
        },
        (_state, props: PropsIntl) => props.consumption !== false,
        (canViewFinancials, showConsumption) => canViewFinancials && showConsumption,
    );

    const consumptionSeriesSelector = createSelector(
        // The assumption here is that the sample_data object is populated directly from the store
        // (rather than deep copied) and thus rarely fails reselect's shallow equality check.
        // As a result, the expensive consumptionMonthlyKWh calc doesn't need to run often
        (state, props: PropsIntl) =>
            props.consumptionData
                ? props.consumptionData.sample_data
                : projSelectors.userConsumption(state, props.project)?.sample_data,
        (_state, props: PropsIntl) => props.project.time_zone_offset,
        showConsumptionSelector,
        (sampleData, tzOffset, showConsumption) =>
            sampleData && showConsumption && uc.consumptionMonthlyKWh(sampleData, tzOffset),
    );

    const chartSelector = createSelector(
        (_state, props: PropsIntl) => props.chartType || 'area',
        (_state, props: PropsIntl) => props.production !== false,
        consumptionSeriesSelector,
        finSelectors.monthlyProduction,
        (_state, props: PropsIntl) => props.theme || DEFAULT_THEME,
        (_state, props: PropsIntl) => props.title,
        (_state, props: PropsIntl) => props.legend,
        (_state, props: PropsIntl) => props.intl,
        (type, showProduction, consumptionSeries, production, theme, title, legend, intl) => {
            const dataSeries: ISeries[] = [];

            if (consumptionSeries) {
                dataSeries.push({
                    type,
                    data: range(1, 13).map((x) => consumptionSeries[x]),
                    name: intl.formatMessage(Translations.financial.consumption),
                    color: theme.secondary_color,
                });
            }
            if (showProduction) {
                dataSeries.push({
                    type,
                    data: production,
                    name: intl.formatMessage(Translations.financial.production),
                    color: theme.primary_color,
                    visible: showProduction,
                });
            }

            return makeConfig(dataSeries, intl, title, legend);
        },
    );

    return (state: IAppState, ownProps: PropsIntl) => {
        return {
            config: chartSelector(state, ownProps),
        };
    };
};

export const ConsumptionEnergyChart: React.ComponentClass<Props> = injectIntl(
    connect(mapStateToProps)(LayoutChart as any) as any,
) as any;

export default ConsumptionEnergyChart;
