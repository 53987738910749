import * as React from 'react';
import { useSelector } from 'react-redux';

import BasicTable from 'reports/components/core/tables/BasicTable';
import FormattedNumber from 'reports/components/core/numbers/FormattedNumber';
import { Current, Distance, Percent, Power, Voltage } from 'reports/components/core/numbers';
import { ModuleCharacterization } from 'reports/models/module';
import * as auth from 'reports/modules/auth';
import { humanizeTimestamp } from 'reports/utils/formatters';

type Props = {
    characterization: ModuleCharacterization;
};

const CharacterizationSpecs = ({ characterization }: Props) => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    return (
        <BasicTable tableTheme="specs" width="100%">
            <tbody>
                <tr>
                    <th>Power</th>
                    <td>
                        <Power value={characterization.power} />
                    </td>
                </tr>
                <tr>
                    <th>
                        V<sub>mp</sub>
                    </th>
                    <td>
                        <Voltage value={characterization.v_mp} precision={3} />
                    </td>
                </tr>
                <tr>
                    <th>
                        V<sub>oc</sub>
                    </th>
                    <td>
                        <Voltage value={characterization.v_oc} precision={3} />
                    </td>
                </tr>
                <tr>
                    <th>
                        I<sub>sc</sub>
                    </th>
                    <td>
                        <Current value={characterization.i_sc} precision={3} />
                    </td>
                </tr>
                <tr>
                    <th>
                        I<sub>mp</sub>
                    </th>
                    <td>
                        <Current value={characterization.i_mp} precision={3} />
                    </td>
                </tr>
                <tr>
                    <th>Dimensions</th>
                    <td>
                        <Distance meters={characterization.width} precision={3} />
                        {' x '}
                        <Distance meters={characterization.length} precision={3} />
                    </td>
                </tr>
                <tr>
                    <th>
                        Temp Coefficient P<sub>max</sub>
                    </th>
                    <td>
                        <FormattedNumber value={characterization.temp_power} precision={3} />
                        %/&deg;C
                    </td>
                </tr>
                <tr>
                    <th>
                        Temp Coefficient V<sub>oc</sub>
                    </th>
                    <td>
                        <FormattedNumber value={characterization.temp_voltage} precision={3} />
                        %/&deg;C
                    </td>
                </tr>
                <tr>
                    <th>
                        Temp Coefficient I<sub>sc</sub>
                    </th>
                    <td>
                        <FormattedNumber value={characterization.temp_current} precision={5} />
                        %/&deg;C
                    </td>
                </tr>
                {user.hasFeature('enable_bifacial') && (
                    <tr>
                        <th>Bifaciality Factor</th>
                        <td>
                            {characterization.bifaciality_factor != null ? (
                                <Percent value={characterization.bifaciality_factor} />
                            ) : (
                                'N/A'
                            )}
                        </td>
                    </tr>
                )}
                <tr>
                    <th>Last Update</th>
                    <td>{characterization.last_update ? humanizeTimestamp(characterization.last_update) : '-'}</td>
                </tr>
            </tbody>
        </BasicTable>
    );
};

export { CharacterizationSpecs };
