import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reports/store';

import _ from 'lodash';
import { Button, Classes, Intent, MenuDivider } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Project } from 'reports/models/project';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as scen from 'reports/models/scenario';
import * as profile from 'reports/models/profile';
import * as auth from 'reports/modules/auth';

import { actions as projActions, selectors as projectSelectors } from 'reports/modules/project';
import Flex from 'reports/components/core/containers/Flex';
import { BehavedButton } from 'reports/components/helpers/common';
import { promptModalForm } from 'reports/components/dialog';
import { CreateButton, PrimaryIntent } from 'reports/components/core/controls';
import { Form, IFormUpdateCallback } from 'reports/components/forms';
import { FormBasicSelect } from 'reports/components/forms/inputs/experimental';

import { Toaster } from 'reports/modules/Toaster';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const DropdownCreateButton = styled(CreateButton)`
    width: 100%;
    margin-left: 0px;
`;

type FinConfigProps = {
    navigateToFinConfig: (finConfig: projFinTemp.ProjectFinancialTemplate) => void;
    project: Project;
    projFinConfig: projFinTemp.ProjectFinancialTemplate;
};

const FinConfigPop = ({ navigateToFinConfig, projFinConfig, project }: FinConfigProps) => {
    const dispatch = useDispatch();
    const finConfigs = useSelector((state: IAppState) =>
        projFinTemp.selectors.all(state, {
            filter: (obj) => obj.project_id === project.project_id,
        }),
    );

    const setPrimaryConfig = async (project, config) =>
        dispatch(projActions.setPrimaryProjectFinancialTemplate(project, config));
    const deleteFinancialConfig = async (finConfig) =>
        dispatch(projFinTemp.api.delete({ project_financial_template_id: finConfig.project_financial_template_id }));

    const items = finConfigs.map((finConfig) => {
        return {
            isPrimary: project.primary_project_financial_template_id === finConfig.project_financial_template_id,
            key: finConfig.project_financial_template_id,
            name: finConfig.name,
            onSetPrimary: async () => setPrimaryConfig(project, finConfig),
            onDelete: async () => deleteFinancialConfig(finConfig),
            onSelect: async () => navigateToFinConfig(finConfig),
        };
    });

    return (
        <SelectPop
            emptyMessage="No configuration"
            items={items}
            selectedId={projFinConfig.project_financial_template_id}
        />
    );
};

export function generateNewConditionSetParams(
    project: Project,
    scenarios: scen.Scenario[],
    defaultProfileScenario: scen.Scenario | undefined,
    bifacialEnabled: boolean = false,
) {
    const scenarioNames = scenarios.map((scenario) => scenario.description);

    // Generate a generic name to use for the new condition set.
    const regexp = /^Condition Set \d+$/;
    const condSetNames = scenarioNames.filter((name) => regexp.test(name));
    const condSetNums = condSetNames.map((name) => parseInt(name.split(' ')[2], 10));
    const numToUse = Math.max(...[0, ...condSetNums]) + 1;
    const defaultScenarioParams = defaultProfileScenario ? defaultProfileScenario : scen.DEFAULT_SCENARIO_PARAMS;
    let bifacialFields = {};
    if (bifacialEnabled) {
        bifacialFields = {
            bifacial_model_enabled: scen.DEFAULT_SCENARIO_PARAMS.bifacial_model_enabled,
            jan_albedo: scen.DEFAULT_SCENARIO_PARAMS.jan_albedo,
            feb_albedo: scen.DEFAULT_SCENARIO_PARAMS.feb_albedo,
            mar_albedo: scen.DEFAULT_SCENARIO_PARAMS.mar_albedo,
            apr_albedo: scen.DEFAULT_SCENARIO_PARAMS.apr_albedo,
            may_albedo: scen.DEFAULT_SCENARIO_PARAMS.may_albedo,
            jun_albedo: scen.DEFAULT_SCENARIO_PARAMS.jun_albedo,
            jul_albedo: scen.DEFAULT_SCENARIO_PARAMS.jul_albedo,
            aug_albedo: scen.DEFAULT_SCENARIO_PARAMS.aug_albedo,
            sep_albedo: scen.DEFAULT_SCENARIO_PARAMS.sep_albedo,
            oct_albedo: scen.DEFAULT_SCENARIO_PARAMS.oct_albedo,
            nov_albedo: scen.DEFAULT_SCENARIO_PARAMS.nov_albedo,
            dec_albedo: scen.DEFAULT_SCENARIO_PARAMS.dec_albedo,
            rear_mismatch_loss_pct: scen.DEFAULT_SCENARIO_PARAMS.rear_mismatch_loss_pct,
            rear_shading_factor_pct: scen.DEFAULT_SCENARIO_PARAMS.rear_shading_factor_pct,
            module_transparency: scen.DEFAULT_SCENARIO_PARAMS.module_transparency,
        };
    }

    // Create a new condition set. Server will attempt to find a weather dataset to use.
    return {
        ...defaultScenarioParams,
        ...bifacialFields,
        project_id: project.project_id,
        description: `Condition Set ${numToUse}`,
        weather_dataset_id: undefined,
    };
}

type NewScenarioButtonProps = {
    navigateToConditionSetEdit: (project: Project, scenario: scen.Scenario) => void;
    project: Project;
    useDropdownStyles?: boolean;
};

const NewScenarioButton = ({ navigateToConditionSetEdit, project, useDropdownStyles }: NewScenarioButtonProps) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const scenarios = useSelector((state: IAppState) =>
        scen.selectors.all(state, {
            filter: (obj) => obj.project_id === project.project_id,
        }),
    );

    const dispatch = useDispatch();
    const getProjectProfileDefaults = (profileId) => dispatch(profile.api.defaults({ profile_id: profileId }));
    const createScenario = (body) => dispatch(scen.api.create(body));
    const setPrimaryScenario = (project, scenario) => dispatch(projActions.setPrimaryScenarioSync(project, scenario));

    const createNewConditionSet = async () => {
        const profileId = project.profile_id || user.default_profile_id;
        const defaultProfileScenario = await getProjectProfileDefaults(profileId);
        const condSetData = generateNewConditionSetParams(
            project,
            scenarios,
            defaultProfileScenario['scenario'],
            user.hasFeature('enable_bifacial'),
        );
        try {
            const resp = await createScenario(condSetData);
            try {
                await setPrimaryScenario(project, resp);
            } catch (exc) {
                Toaster.show({
                    message: `Condition Set created, but unable to set as primary: ${exc}`,
                    intent: Intent.DANGER,
                });
            } finally {
                navigateToConditionSetEdit(project, resp);
            }
        } catch (exc) {
            Toaster.show({
                message: `Create Condition Set failed: ${exc}`,
                intent: Intent.DANGER,
            });
        }
    };
    return useDropdownStyles ? (
        <DropdownCreateButton text="New Condition Set" icon={IconNames.ADD} onClick={createNewConditionSet} />
    ) : (
        <CreateButton text="New Condition Set" icon={IconNames.ADD} onClick={createNewConditionSet} />
    );
};

type ConditionsProps = {
    navigateToConditionSet: (project: Project, scenario: scen.Scenario) => void;
    navigateToConditionSetEdit: (project: Project, scenario: scen.Scenario) => void;
    project: Project;
    scenario: scen.Scenario;
};

const ConditionsPop = ({ navigateToConditionSet, navigateToConditionSetEdit, project, scenario }: ConditionsProps) => {
    const scenarios = useSelector((state: IAppState) =>
        scen.selectors.all(state, {
            filter: (obj) => obj.project_id === project.project_id,
        }),
    );

    const primaryScenario = useSelector((state: IAppState) => projectSelectors.primaryScenario(state, { project }));

    const dispatch = useDispatch();

    const deleteScenario = (scenarioToDelete: scen.Scenario) => {
        // If we are currently viewing or editing a scenario while trying to delete it,
        // redirect to the primary scenario to avoid issues.
        if (
            primaryScenario &&
            scenarioToDelete.scenario_id === scenario.scenario_id &&
            primaryScenario.scenario_id !== scenarioToDelete.scenario_id
        ) {
            navigateToConditionSet(project, primaryScenario);
        }

        dispatch(
            scen.api.delete({
                scenario_id: scenarioToDelete.scenario_id,
            }),
        );
    };

    const setPrimaryScenario = (project: Project, primaryScenario: scen.Scenario) =>
        dispatch(projActions.setPrimaryScenarioSync(project, primaryScenario));

    const items = scenarios.map((scenario) => {
        return {
            key: scenario.scenario_id,
            isPrimary: project.primary_scenario_id === scenario.scenario_id,
            name: scenario.description,
            onSetPrimary: async () => setPrimaryScenario(project, scenario),
            onDelete: async () => deleteScenario(scenario),
            onSelect: async () => navigateToConditionSet(project, scenario),
        };
    });

    return (
        <SelectPop
            emptyMessage="No configuration"
            items={items}
            selectedId={scenario.scenario_id}
            button={
                <NewScenarioButton
                    navigateToConditionSetEdit={navigateToConditionSetEdit}
                    project={project}
                    useDropdownStyles={true}
                />
            }
        />
    );
};

interface INewPrimaryForm {
    new_primary: number;
}

interface IDeletePrimaryProps {
    items: SelectPopItem[];
    onUpdate: IFormUpdateCallback;
}

class DeletePrimaryForm extends React.Component<IDeletePrimaryProps> {
    render() {
        const { items, onUpdate } = this.props;
        const selectItems = items.filter((item) => !item.isPrimary);

        return (
            <Form baseValue={{ new_primary: selectItems[0].key }} onSubmit={this.onSubmit} onUpdate={onUpdate}>
                <p>
                    Please select an existing condition set to replace your current primary condition set for this
                    project. This will be used as the project's default condition set.
                </p>
                <FormBasicSelect<SelectPopItem>
                    path="new_primary"
                    dataSource={{
                        items: selectItems,
                        keyLookup: ({ key }) => key,
                    }}
                    itemRenderer={({ key, name }) => ({ key, text: name })}
                />
            </Form>
        );
    }

    onSubmit = (formData: INewPrimaryForm) => {
        const { items } = this.props;
        return items.find((item) => item.key === formData.new_primary);
    };
}

async function showDeletePrimaryDialog(items: SelectPopItem[]) {
    return await promptModalForm<SelectPopItem>({
        title: 'Delete Primary Condition Set',
        Component: DeletePrimaryForm,
        componentProps: { items },
        cancellable: true,
        submitLabel: 'Save',
        submitIntent: PrimaryIntent.SAVE,
    });
}

type SelectPopItem = {
    isPrimary: boolean;
    key: number;
    name: string;
    onSelect: () => Promise<any>;
    onDelete: () => Promise<any>;
    onSetPrimary: () => Promise<any>;
};

type SelectPopProps = {
    items: SelectPopItem[];
    emptyMessage: string;
    selectedId: number;
    button?: React.ReactNode;
};

class SelectPop extends React.Component<SelectPopProps> {
    render() {
        if (!this.props.items.length) {
            return (
                <div style={{ padding: '12px' }}>
                    <i>{this.props.emptyMessage}</i>
                </div>
            );
        }

        const sortedItems = _.sortBy(this.props.items, (i) => i.name);

        const elements = sortedItems.map((itemProps, idx) => {
            return (
                <Flex.Container key={idx} style={{ padding: 2 }}>
                    <div style={{ margin: '0px 4px' }}>
                        {itemProps.isPrimary ? (
                            <Button icon="star" minimal small disabled />
                        ) : (
                            <Button icon="star-empty" minimal small onClick={() => itemProps.onSetPrimary()} />
                        )}
                    </div>
                    <Flex.Main style={{ margin: '0px 4px' }}>
                        <BehavedButton
                            minimal
                            small
                            fill
                            className={Classes.POPOVER_DISMISS}
                            text={itemProps.name}
                            onClick={() => itemProps.onSelect()}
                        />
                    </Flex.Main>
                    <div style={{ margin: '0px 4px' }}>
                        <Button
                            icon="trash"
                            minimal
                            small
                            onClick={async () => {
                                if (itemProps.isPrimary) {
                                    const newPrimaryItem = await showDeletePrimaryDialog(this.props.items);
                                    await newPrimaryItem.onSetPrimary();
                                    await newPrimaryItem.onSelect();
                                }
                                await itemProps.onDelete();
                            }}
                            disabled={this.props.items.length === 1}
                        />
                    </div>
                </Flex.Container>
            );
        });

        return (
            <div
                style={{
                    padding: '4px',
                    width: '220px',
                    maxHeight: '320px',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                }}
            >
                {elements}
                {elements.length && this.props.button && (
                    <>
                        <MenuDivider />
                        <div style={{ width: '100%', padding: 4 }}>{this.props.button}</div>
                    </>
                )}
            </div>
        );
    }
}

export { FinConfigPop, ConditionsPop, NewScenarioButton };
