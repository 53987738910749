/* tslint:disable:variable-name */
import moment from 'moment';

import { BaseClass, ReduxEndpoint } from 'reports/utils/api';
import { fallback } from 'reports/utils/helpers';

import { schema } from 'reports/models/schema';
import { IAPIQueryOpts } from 'reports/models/types';
import * as team from 'reports/models/team';
import { ModuleCharacterization, schemaObj as charSchemaObj } from './ModuleCharacterization';

class Module extends BaseClass {
    module_id: number;
    manufacturer: string;
    name: string;
    power: number;
    cell_technology_name: CellTechnologyType;
    cell_technology: CellTechnology;
    series_cells: number;
    parallel_cells: number;

    characterizations: ModuleCharacterization[];

    // metadata
    team_id: number;
    team?: team.Team;
    public: boolean;
    last_update?: moment.Moment;
    source?: string;

    default_characterization_id: number;
    default_characterization?: ModuleCharacterization;
    user_default_characterization_id?: number;
    user_default_characterization?: ModuleCharacterization;
    favorite: boolean;

    module_type: ModuleType;

    constructor(data: any) {
        super(Module.deserializer(data));
    }

    static deserializer = BaseClass.getDeserializer({
        last_update: (x) => moment(x),
    });

    defaultCharacterizationId = (): number => {
        return fallback(this.user_default_characterization_id, this.default_characterization_id);
    };

    defaultCharacterization = (): ModuleCharacterization => {
        return fallback(this.user_default_characterization, this.default_characterization);
    };
}

// There are more types defined in the backend enum, but these are the only ones currently in use.
enum CellTechnologyTypes {
    si_mono = 'Si-Mono',
    si_poly = 'Si-Poly',
    a_si_h_single = 'a-Si:H single',
    ucsi_asi_h = 'uCSi-aSi:H',
    cdte = 'CdTe',
    cis = 'CIS',
    hit = 'HIT',
    default = 'Default',
}
type CellTechnologyType = keyof typeof CellTechnologyTypes;

enum ModuleTypes {
    bifacial = 'Bifacial',
    monofacial = 'Monofacial',
    mixed = 'Mixed',
}
type ModuleType = keyof typeof ModuleTypes;

interface CellTechnology {
    vbi: number;
    adj_bandgap: number;
}

const schemaObj = schema.addObject(Module, 'module', {
    relationships: {
        team: { schema: team.schemaObj },
        default_characterization: { schema: charSchemaObj },
        user_default_characterization: { schema: charSchemaObj },
    },
});

const moduleBackrefSelectors = charSchemaObj.addRelationship('module', schemaObj, { backref: 'characterizations' });

interface IModuleQuery extends IAPIQueryOpts {
    name?: string;
    manufacturer?: string;
    favorite?: boolean;
    cell_technology_name?: string;
}

interface IUpdateModuleForm {
    module_id: number;

    favorite?: boolean;
    public?: boolean;
    user_default_characterization_id?: number;
}

interface ICreateModuleForm {
    file: File;
    module_name?: string;
    characterization_name?: string;
    characterization_desc?: string;
}

interface IModuleMatchExistsForm {
    module_name: string;
    module_manufacturer: string;
    module_team_id: number;
}

const endpoint = ReduxEndpoint.fromSchema('/api/modules/', schemaObj, {
    deepSelect: {
        team: true,
        default_characterization: true,
        user_default_characterization: true,
    },
});

const api = {
    index: endpoint.index<IModuleQuery>(),
    get: endpoint.get<{ module_id: number | string }>('{module_id}'),
    moduleMatchExists: endpoint.get<IModuleMatchExistsForm, { exists: boolean }>(
        'module_match_exists',
        ReduxEndpoint.PassThroughConfig(),
    ),
    save: endpoint.put<IUpdateModuleForm>('{module_id}'),
    create: endpoint.post<ICreateModuleForm, undefined, ModuleCharacterization>(),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,

    allCharacterizations: moduleBackrefSelectors.backrefSelector,
};

export default Module;

export { Module, ModuleTypes, CellTechnologyTypes, schemaObj, api, selectors };
