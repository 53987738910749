import * as React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectors as authSelectors } from 'reports/modules/auth';
import * as cfg from 'reports/config';
import { IAppState } from 'reports/types';

import { Button, Intent, Tag } from '@blueprintjs/core';

import { Flex } from 'reports/components/core/containers';
import Section2 from 'reports/components/core/containers/Section2';

import { ScheduledChanges, Subscription } from 'reports/models/subscription';
import { Team, api as teamApi } from 'reports/models/team';
import { User, api as userApi } from 'reports/models/user';

import { canManageBilling, canManageTeamResources } from 'reports/modules/auth/permissions';

import { styled } from 'reports/styles/styled-components';

import { InviteMemberDialog } from './InviteMemberDialog';

import TeamMemberList from './TeamMemberList';

import { promptRestartTrial } from './promptRestartTrial';
import SSOEnabledWarning from 'reports/modules/settings/team/components/SSOEnabledWarning';

interface Props {
    team: Team;
    subscription?: Subscription;
    user: User;
    navigateToBilling: () => void;
}

const Section = styled(Section2)`
    margin-top: 30px;
    padding-bottom: 10;
`;

const Debug = ({ restartTrial }) => (
    <Section title="Trial Management" contextEl={<Tag intent={Intent.DANGER}>Internal testing only</Tag>}>
        <Button onClick={restartTrial}>Restart Trial</Button>
    </Section>
);

const InviteButton = styled(Button)`
    margin-bottom: 10px;
    float: right;
`;

const TeamManagement = React.memo(({ navigateToBilling, subscription, team, user }: Props) => {
    const authUser = useSelector((state) => authSelectors.getUser(state)!);
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));

    const dispatch = useDispatch();

    const restartTrial = (user: User) => dispatch(userApi.restartTrial({ email: user.email }));
    const reloadTeam = (team: Team) => dispatch(teamApi.get({ team_id: team.team_id }));
    const [inviteDialogIsOpen, setInviteDialogIsOpen] = React.useState<boolean>(false);
    const handleInviteDialogClose = () => setInviteDialogIsOpen(false);

    const seatsFilled = subscription && subscription.paid_seats <= subscription.used_seats;

    const canInviteMember =
        subscription &&
        subscription.is_active &&
        canManageBilling(authUser) &&
        (subscription.scheduled_changes === ScheduledChanges.cancel_at_period_end ? !seatsFilled : true);

    const hasPaymentFailure = subscription?.has_failed_payment;

    const onInviteClick = () => {
        if (seatsFilled) {
            return navigateToBilling();
        }
        return setInviteDialogIsOpen(true);
    };

    return (
        <>
            <InviteMemberDialog
                subscription={subscription!!}
                team={team}
                isOpen={inviteDialogIsOpen}
                handleClose={handleInviteDialogClose}
            />
            <Flex.ContainerV>
                <Section2 title="Manage Team">
                    {team.has_sso ? (
                        <SSOEnabledWarning />
                    ) : (
                        <InviteButton
                            disabled={
                                !canInviteMember ||
                                (hasPaymentFailure && seatsFilled) ||
                                (subscription.is_contract && seatsFilled)
                            }
                            onClick={onInviteClick}
                            intent={Intent.PRIMARY}
                            text="Invite Team Member"
                        />
                    )}
                    <TeamMemberList
                        disabled={!canManageTeamResources(authUser, team)}
                        subscription={subscription}
                        team={team}
                    />
                    {config?.debug && (
                        <Debug restartTrial={() => promptRestartTrial(user, team, restartTrial, reloadTeam)} />
                    )}
                </Section2>
            </Flex.ContainerV>
        </>
    );
});

export { TeamManagement };
