import React, { useState } from 'react';

import { Classes, Divider } from '@blueprintjs/core';

import { FormConsumer } from 'reports/components/forms/types';
import PercentInput from 'reports/components/forms/inputs/experimental/PercentInput';
import FormPercentInput from 'reports/components/forms/inputs/experimental/FormPercentInput';

import * as styles from 'reports/styles/styled-components';
import { FormBareNumericInput } from 'reports/components/forms/inputs/experimental';
import { FormattedInput } from 'reports/components/helpers';
const styled = styles.styled;

export const MonthlyValuesInputGroup = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    && .${Classes.INPUT_GROUP} .${Classes.INPUT} {
        width: 100%;
    }
`;

export const MonthlyValuesEditorContainer = styled.div`
    display: flex;
`;

export const PaddedDivider = styled(Divider)`
    margin: 0 12px;
`;

const StyledNumericInput = styled(FormBareNumericInput)`
    display: block;
`;

interface ConditionSetMonthlyValuesEditProps {
    fieldMap: { path: string; monthName: string }[];
    defaultAllValue: number;
    isPercentage?: boolean;
    min?: number;
    max?: number;
}

/**
 * Editor for monthly value fields (one for each month) in condition sets, such as soiling. Has a special "All" field that sets the
 * others to the same value. This assumes it exists with a FormContext given by FormProvider, most likely within
 * a Form component.
 */
export const ConditionSetMonthlyValuesEdit = ({
    fieldMap,
    defaultAllValue,
    isPercentage = true,
    min,
    max,
}: ConditionSetMonthlyValuesEditProps) => {
    const [allValue, setAllValue] = useState<number>(defaultAllValue);
    const onAllChange = (val, updateValues) => {
        let allValue = val;
        if (max && parseFloat(val) > max) {
            allValue = max;
        }
        if (min !== null && min !== undefined && parseFloat(val) < min) {
            allValue = min;
        }
        setAllValue(allValue);

        const allSetValues = {};
        for (const monthPath of Object.keys(fieldMap).map((k) => fieldMap[k].path)) {
            allSetValues[monthPath] = allValue;
        }
        updateValues(allSetValues, true);
    };

    return (
        <MonthlyValuesEditorContainer>
            <MonthlyValuesInputGroup>
                <label>Set All</label>
                <FormConsumer>
                    {({ updateValues, submitting }) =>
                        isPercentage ? (
                            <PercentInput
                                value={allValue || ''}
                                onChange={(val: number) => onAllChange(val, updateValues)}
                                disabled={submitting}
                            />
                        ) : (
                            <FormattedInput
                                value={allValue || 0}
                                onConfirm={(val: number) => onAllChange(val, updateValues)}
                                disabled={submitting}
                                numericInputOnly={true}
                            />
                        )
                    }
                </FormConsumer>
            </MonthlyValuesInputGroup>
            <PaddedDivider />
            {fieldMap.map((month) => (
                <MonthlyValuesInputGroup key={month.path}>
                    <label>{month.monthName}</label>
                    {isPercentage ? (
                        <FormPercentInput path={month.path} />
                    ) : (
                        <StyledNumericInput path={month.path} min={min} max={max} minorStepSize={null} stepSize={0.1} />
                    )}
                </MonthlyValuesInputGroup>
            ))}
        </MonthlyValuesEditorContainer>
    );
};

export default ConditionSetMonthlyValuesEdit;
