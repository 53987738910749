import * as React from 'react';
import { keyBy, chain } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from '@blueprintjs/core';

import BasicTable from 'reports/components/core/tables/BasicTable';
import BasicSelect from 'reports/components/forms/inputs/experimental/BasicSelect';

import * as scen from 'reports/models/scenario';
import * as mod from 'reports/models/module';
import * as pd from 'reports/models/power_device';

import * as auth from 'reports/modules/auth';

interface Props {
    scenario: scen.Scenario;
    scenMCs: scen.ScenarioModuleCharacterization[];
    scenPDCs: scen.ScenarioPowerDeviceCharacterization[];
    onScenMCsChange: (scenMCs: mod.ModuleCharacterization[]) => void;
    onScenPDCsChange: (scenPDCs: pd.PowerDeviceCharacterization[]) => void;
}

/**
 * Generate a new array of Scenario*Characterizations based on component defaults, previous characterizations,
 * and newly selected characterization. This works for both modules and power devices.
 */
const onSelectChar = (prevScenChars, selectedCharId, componentId, projComponents, componentIdField, charIdField) => {
    const prevComponentToChar = keyBy(prevScenChars, 'module_id');

    // Map over each project component and pick a characterization with the following precedence:
    // (1) just selected by the user (2) previous value (3) component default
    const newScenChars = projComponents.map((component) => ({
        scenario_id: component.scenario_id,
        [componentIdField]: component[componentIdField],
        [charIdField]:
            componentId === component[componentIdField]
                ? selectedCharId
                : (prevComponentToChar[component[componentIdField]] || component.defaultCharacterization())[
                      charIdField
                  ],
    }));

    // We generate an entirely new array of Scenario*Characterizations
    // so that Form dirty checking works without special handling for arrays
    return newScenChars;
};

interface ModuleCharRowsProps {
    projMCs: mod.ModuleCharacterization[] | undefined;
    scenMCs: scen.ScenarioModuleCharacterization[];
    onScenMCsChange: (scenMCs: mod.ModuleCharacterization[]) => void;
    bifacialEnabled: boolean;
}

const ModuleCharRows = ({ projMCs, scenMCs, onScenMCsChange, bifacialEnabled }: ModuleCharRowsProps) => {
    const scenMCsByModuleId = keyBy(scenMCs, 'module_id');

    const sortedModules = chain(projMCs).map('module').uniq().sortBy(['manufacturer', 'name']).value();

    const charsForModule = chain(projMCs).groupBy('module_id').value();

    const mcsById = keyBy(projMCs, 'module_characterization_id');

    const onSelectMC = (char, projModules) => {
        const newScenMCs = onSelectChar(
            scenMCs,
            char.module_characterization_id,
            char.module_id,
            projModules,
            'module_id',
            'module_characterization_id',
        );
        onScenMCsChange(newScenMCs);
    };

    return (
        <>
            {sortedModules.map((module) => {
                const selectedMCId = (scenMCsByModuleId[module.module_id] || module.defaultCharacterization())
                    .module_characterization_id;
                const selectedMC = mcsById[selectedMCId];
                const moduleChars = charsForModule[module.module_id];

                return (
                    <tr key={module.module_id}>
                        <td>Module</td>
                        <td>
                            {module.name} ({module.manufacturer})
                        </td>
                        <td>
                            <BasicSelect
                                dataSource={{
                                    items: moduleChars,
                                }}
                                value={selectedMC}
                                itemRenderer={(char) => ({
                                    key: char.module_characterization_id,
                                    text: char.name,
                                })}
                                onChange={(char) => onSelectMC(char, sortedModules)}
                                disabled={moduleChars.length <= 1}
                            />
                        </td>
                        <td>{module.team!.name}</td>
                        <td>{selectedMC.description}</td>
                        {bifacialEnabled && <td>{selectedMC.is_bifacial ? 'True' : 'False'}</td>}
                    </tr>
                );
            })}
        </>
    );
};

interface DeviceCharRowsProps {
    projPDCs: pd.PowerDeviceCharacterization[] | undefined;
    scenPDCs: scen.ScenarioPowerDeviceCharacterization[];
    onScenPDCsChange: (scenPDCs: pd.PowerDeviceCharacterization[]) => void;
    bifacialEnabled: boolean;
}

const DeviceCharRows = ({ projPDCs, scenPDCs, onScenPDCsChange, bifacialEnabled }: DeviceCharRowsProps) => {
    const scenPDCsById = keyBy(scenPDCs, 'power_device_id');

    const sortedDevices = chain(projPDCs)
        .map('power_device')
        .uniq()
        .sortBy(['device_type_name', 'manufacturer', 'name'])
        .value();

    const charsForDevice = chain(projPDCs).groupBy('power_device_id').value();

    const pdcsById = keyBy(projPDCs, 'power_device_characterization_id');

    const onSelectPDC = (selectedPdc, projDevices) => {
        const newScenPDCs = onSelectChar(
            scenPDCs,
            selectedPdc.power_device_characterization_id,
            selectedPdc.power_device_id,
            projDevices,
            'power_device_id',
            'power_device_characterization_id',
        );
        onScenPDCsChange(newScenPDCs);
    };

    return (
        <>
            {sortedDevices.map((device) => {
                const selectedPDCId = (scenPDCsById[device.power_device_id] || device.defaultCharacterization())
                    .power_device_characterization_id;
                const selectedPDC = pdcsById[selectedPDCId];
                const deviceChars = charsForDevice[device.power_device_id];

                return (
                    <tr key={device.power_device_id}>
                        <td>{pd.PowerDeviceTypes[device.device_type_name]}</td>
                        <td>
                            {device.name} ({device.manufacturer})
                        </td>
                        <td>
                            <BasicSelect
                                dataSource={{
                                    items: deviceChars,
                                }}
                                value={selectedPDC}
                                itemRenderer={(char) => ({
                                    key: char.power_device_characterization_id,
                                    text: char.name,
                                })}
                                onChange={(char) => onSelectPDC(char, sortedDevices)}
                                disabled={deviceChars.length <= 1}
                            />
                        </td>
                        <td>{device.team!.name}</td>
                        <td>{selectedPDC.description}</td>
                        {bifacialEnabled && <td>N/A</td>}
                    </tr>
                );
            })}
        </>
    );
};

export const CharacterizationEditTable = ({
    scenario,
    scenMCs,
    scenPDCs,
    onScenMCsChange,
    onScenPDCsChange,
}: Props) => {
    const [loading, setLoading] = React.useState(true);
    const [projDeviceCharacterizations, setProjDeviceCharacterizations] = React.useState<
        pd.PowerDeviceCharacterization[] | undefined
    >(undefined);
    const [projModuleCharacterizations, setProjModuleCharacterizations] = React.useState<
        mod.ModuleCharacterization[] | undefined
    >(undefined);

    const dispatch = useDispatch();

    React.useEffect(() => {
        const loadProjectCharacterizations = async () => {
            setLoading(true);
            setProjDeviceCharacterizations(undefined);
            setProjModuleCharacterizations(undefined);

            const [projModuleCharacterizations, projDeviceCharacterizations] = await Promise.all([
                dispatch(mod.charApi.index({ project_id: scenario.project_id })),
                dispatch(pd.charApi.index({ project_id: scenario.project_id })),
            ]);

            setProjDeviceCharacterizations(projDeviceCharacterizations);
            setProjModuleCharacterizations(projModuleCharacterizations);
            setLoading(false);
        };

        loadProjectCharacterizations();
    }, [scenario.project_id]);

    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const bifacialEnabled = user.hasFeature('enable_bifacial');

    return (
        <BasicTable>
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Component</th>
                    <th>Characterization</th>
                    <th>Uploaded By</th>
                    <th>Description</th>
                    {bifacialEnabled && <th>Bifacial</th>}
                </tr>
            </thead>
            <tbody>
                {loading && (
                    <tr>
                        <td colSpan={5}>
                            <Spinner />
                        </td>
                    </tr>
                )}

                <ModuleCharRows
                    scenMCs={scenMCs}
                    projMCs={projModuleCharacterizations}
                    onScenMCsChange={onScenMCsChange}
                    bifacialEnabled={bifacialEnabled}
                />
                <DeviceCharRows
                    scenPDCs={scenPDCs}
                    projPDCs={projDeviceCharacterizations}
                    onScenPDCsChange={onScenPDCsChange}
                    bifacialEnabled={bifacialEnabled}
                />
            </tbody>
        </BasicTable>
    );
};

export default CharacterizationEditTable;
