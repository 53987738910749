import * as React from 'react';

import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';

import { ILayoutChartProps, LayoutChart } from 'reports/modules/report/components/charts';
import { registerWidget, IWidgetRenderProps, IReportContext } from 'reports/modules/report/widgets';
import { Scenario } from 'reports/models/scenario';
import { generateP90ChartConfig } from 'helioscope/app/utilities/probability-distribution-graph';
import { Simulation } from 'reports/models/simulation';

interface P90ChartProps extends ILayoutChartProps {
    scenario: Scenario;
    simulation: Simulation;
}

export const p90UserHasAccess = (mode: string, user: any, scenario: Scenario) => {
    const probDistConfig = scenario?.probability_distribution_config;
    const isP90Enabled =
        probDistConfig?.is_enabled &&
        Object.values(probDistConfig.probability_values).some((pValue) => Boolean(pValue));

    return user?.hasP90WeatherVariabilityAccess() === true && (mode === 'edit' || isP90Enabled);
};

export const P90Chart = ({ scenario, simulation, layout }: P90ChartProps) => {
    const gridPower = simulation.metadata.grid_power;

    const chartConfig = React.useMemo(
        () => generateP90ChartConfig(gridPower, scenario.probability_distribution_config),
        [gridPower, scenario.probability_distribution_config],
    );

    return <LayoutChart config={chartConfig} layout={layout} />;
};

type Context = Pick<IReportContext, 'scenario' | 'simulation'>;
type Props = IWidgetRenderProps<object, Context> & ILayoutChartProps;

export const P90ChartWidget = registerWidget('p90_chart', {
    Component: ({ context, config }: Props) => <P90Chart {...context} layout={config.layout} />,
    EditingComponent: ({ context, config }: Props) => (
        <P90Chart mouseEvents={false} {...context} layout={config.layout} />
    ),
    metadata: {
        category: 'project',
        dimensions: { h: 326, w: 350 },
        displayName: Translations.widgets.p90_chart_header,
        icon: IconNames.TIMELINE_AREA_CHART,
        userHasAccess: p90UserHasAccess,
    },
    dependencies: ['scenario', 'probabilityConfig', 'simulation'],
});
