import * as React from 'react';

import { FormGroup, Classes, Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import * as pro from 'reports/models/profile';

import { Section2 } from 'reports/components/core/containers';
import {
    FormNumericInput,
    FormUserDistanceInput,
    FormUnitsInput,
    FavoriteSelectIcon,
    ICON_MARGIN,
    ICON_SIZE,
} from 'reports/components/forms/inputs/experimental';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

// Constants
const LABEL_WIDTH = 180;
const NUMERIC_INPUT_WIDTH = 150;

export const AlignmentIcons = {
    left: <Icon icon={IconNames.ALIGN_LEFT} />,
    center: <Icon icon={IconNames.ALIGN_CENTER} />,
    right: <Icon icon={IconNames.ALIGN_RIGHT} />,
    block: <Icon icon={IconNames.ALIGN_JUSTIFY} />,
};

const FORM_GROUP_PROPS = {
    inline: true,
    bold: true,
    labelWidth: LABEL_WIDTH,
    inputWidth: NUMERIC_INPUT_WIDTH,
};

// General components
export const ProfileNumericInput = ({ path, label, helperText = '', ...otherProps }) => {
    return (
        <FormNumericInput
            path={path}
            label={label}
            helperText={helperText}
            placeholder=""
            {...otherProps}
            {...FORM_GROUP_PROPS}
        />
    );
};

export const ProfileUserDistanceInput: React.FC<{
    path: string;
    label: string;
    helperText?: string;
}> = ({ path, label, helperText }) => (
    <FormUserDistanceInput path={path} label={label} helperText={helperText} {...FORM_GROUP_PROPS} />
);

export const ProfileUnitsInput: React.FC<{
    path: string;
    label: string;
    unitTagText: string;
}> = ({ path, label, unitTagText }) => (
    <FormUnitsInput path={path} label={label} unitTagText={unitTagText} {...FORM_GROUP_PROPS} />
);

const TableLabel = styled.div`
    font-weight: bold;
    margin-top: 5px;
    margin-bottom: 10px;
`;

const SubsectionPanel = styled.div<{ margin: string; padding: string }>`
    display: inline-block;
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
    border-color: #000000;
    background-color: #f5f5f5;
    border: 1px solid transparent;
    border-radius: 4px;
    ${(props) => `margin: ${props.margin};`}
    ${(props) => `padding: ${props.padding};`}
    margin-left: ${LABEL_WIDTH + 10}px;
`;

const ProfilesParameter = styled(FormGroup).attrs({
    inline: true,
})`
    .${Classes.LABEL} {
        min-width: ${LABEL_WIDTH}px;
        width: ${LABEL_WIDTH}px;
    }

    .${Classes.FORM_CONTENT} {
        margin-top: 5px; /* align content with label top */
    }

    margin-bottom: 0px;
`;

// ProfileSelect Item Renderer
const FavoriteItemRenderer = (profile: pro.Profile) => {
    return {
        key: profile.profile_id,
        // Note: "text" property is unused because "selectOption" overrides it.
        text: profile.toString(),
        selectOption: profile.bookmarked() ? (
            <>
                <FavoriteSelectIcon />
                {profile.toString()}
            </>
        ) : (
            <span style={{ marginLeft: ICON_SIZE + ICON_MARGIN }}>{profile.toString()}</span>
        ),
    };
};

export {
    LABEL_WIDTH,
    NUMERIC_INPUT_WIDTH,
    TableLabel,
    Section2,
    ProfilesParameter,
    SubsectionPanel,
    FavoriteItemRenderer,
};
