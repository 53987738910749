import * as React from 'react';

import * as scen from 'reports/models/scenario';
import * as usr from 'reports/models/user';

import { Flex, Section2 } from 'reports/components/core/containers';

import CharacterizationTable from './CharacterizationTable';
import {
    AdvancedSection,
    CellTempTable,
    MismatchSection,
    ScenarioParameter,
    MonthlyValuesTable,
} from 'reports/modules/condition_sets/components/CondSetDisplayComponents';
import { P90SectionSubtitle, ProbabilityDistributionConfig } from './ConditionSetP90Edit';
import { SystemVariabilityTable } from './SystemVariability';
import { WeatherVariabilityTable } from './WeatherVariability';
import * as styles from 'reports/styles/styled-components';
import { Percent } from 'reports/components/core/numbers';
const styled = styles.styled;

const WeatherProbabilityValuesSpan = styled.span`
    color: #5f6b7c;
    margin-left: 8px;
`;

interface BifacialSectionProps {
    scenario: scen.Scenario;
}

const P90Section: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => {
    const probDistConfig: ProbabilityDistributionConfig =
        scenario.probability_distribution_config || scen.DEFAULT_SCENARIO_PARAMS.probability_distribution_config;

    return (
        <Section2 title="Production Probability" subtitle={P90SectionSubtitle}>
            <ScenarioParameter
                label="Probability Distribution"
                helperText="Enable this feature to calculate and display probability-based production values."
            >
                {probDistConfig.is_enabled ? 'Enabled' : 'Disabled'}
            </ScenarioParameter>
            <ScenarioParameter label="Probability Values">
                {Object.entries(probDistConfig.probability_values).map(([key, value]) => (
                    <div key={key}>
                        <span>{key.toUpperCase()}</span>
                        <WeatherProbabilityValuesSpan>{value ? 'On' : 'Off'}</WeatherProbabilityValuesSpan>
                    </div>
                ))}
            </ScenarioParameter>
            <ScenarioParameter label="Variability Factors">
                <Flex.Container style={{ gap: '16px' }}>
                    <div style={{ width: '50%' }}>
                        <WeatherVariabilityTable scenario={scenario} displayMode="view" />
                    </div>
                    <div style={{ width: '50%' }}>
                        <SystemVariabilityTable scenario={scenario} displayMode="view" />
                    </div>
                </Flex.Container>
            </ScenarioParameter>
            <ScenarioParameter label="Resulting Weather & System Variability">
                {probDistConfig.total_variability.toFixed(2)}%
            </ScenarioParameter>
        </Section2>
    );
};

const MeteoSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Weather" subtitle="Used to calculate the hourly performance of the array for a given year">
        <ScenarioParameter
            label="Weather Dataset"
            helperText="Used to calculate the hourly performance of the array for a given year"
        >
            <a href={scenario.weather_dataset.get_url}>{`${scenario.weather_dataset}`}</a>
        </ScenarioParameter>
    </Section2>
);

const HorizonShadingSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Horizon Shading">
        <ScenarioParameter label="Horizon Profile">
            {scenario.horizon ? scenario.horizon.description : 'No Horizon File Selected'}
        </ScenarioParameter>
    </Section2>
);

const ScenarioCellTempTable: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <CellTempTable cellTempModel={scenario.cell_temp_model} cellTempParams={scenario.cell_temp_parameters} />
);

const SoilingSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Soiling" subtitle="Sources of energy loss">
        <ScenarioParameter label="Soiling" helperText="Derates to irradiance due to module soiling or other factors">
            <MonthlyValuesTable values={scenario.soiling} isPercentage />
        </ScenarioParameter>
    </Section2>
);

const CellTemperatureSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Cell Temperature">
        <ScenarioParameter
            label="Cell Temperature Model"
            helperText="Used to determine cell temperature based on environmental factors and module irradiance. The Sandia Temperature Model is quantitatively derived from module performance in the field,
            the diffusion model is equivalent to that used in PVSyst."
        >
            {scenario.cell_temp_model === 'sandia' ? 'Sandia' : 'Diffusion'} Model
        </ScenarioParameter>

        <ScenarioParameter label="Coefficients">
            <ScenarioCellTempTable scenario={scenario} />
        </ScenarioParameter>
    </Section2>
);

const AcLossesSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Ac Losses">
        <ScenarioParameter
            label="AC System Derate"
            helperText={
                <span>
                    Constant derate factor to compensate for AC system losses (conductors, transformers, etc) that is
                    applied to the inverter's AC Output within the loss tree.{' '}
                    <strong>This will only be used if you do not define an AC subsystem on your design.</strong>
                </span>
            }
        >
            {scenario.ac_conductor_derate}%
        </ScenarioParameter>
    </Section2>
);

const BifacialSection = ({ scenario }: BifacialSectionProps) => (
    <Section2
        title="Bifacial System"
        subtitle="Improve simulation accuracy by accounting for rear-side energy gains with bifacial modules. For more information about this feature visit our Help Center."
    >
        <ScenarioParameter
            label="Bifacial Model"
            helperText="Enable rear side gains in simulation when bifacial modules are used."
        >
            {scenario.bifacial_model_enabled ? 'Enabled' : 'Disabled'}
        </ScenarioParameter>
        <ScenarioParameter
            label="Albedo"
            helperText="Albedo is a decimal value between 0.0 and 1.0, representing the fraction of sunlight reflected off a surface. For instance, an albedo of 0.2 means 20% reflected. Higher albedo increases light reflected onto the backside of bifacial solar panels, boosting their energy efficiency. Examples of albedo values can be found here."
        >
            <MonthlyValuesTable
                values={scenario.albedo.map((a) => (a !== null ? a : scen.DEFAULT_SCENARIO_PARAMS.default_albedo))}
            />
        </ScenarioParameter>
        <ScenarioParameter
            label="Rear Mismatch Loss"
            helperText="Rear Mismatch Loss accounts for the non-uniformity of the irradiance on the rear-side of the module. "
        >
            <Percent
                value={(scenario.rear_mismatch_loss_pct || scen.DEFAULT_SCENARIO_PARAMS.rear_mismatch_loss_pct) / 100}
            />
        </ScenarioParameter>
        <ScenarioParameter
            label="Rear Shading Factor"
            helperText="Rear Shading Factor accounts for the shading caused by the mechanical structures behind the modules."
        >
            <Percent
                value={(scenario.rear_shading_factor_pct || scen.DEFAULT_SCENARIO_PARAMS.rear_shading_factor_pct) / 100}
            />
        </ScenarioParameter>
        <ScenarioParameter
            label="Module Transparency"
            helperText="The percentage of light that passes through a bifacial module, its frame, or its racking structure. This light will cause additional ground irradiance, which can be reflected to the rear side and produce additional energy in a bifacial system."
        >
            <Percent value={(scenario.module_transparency || scen.DEFAULT_SCENARIO_PARAMS.module_transparency) / 100} />
        </ScenarioParameter>
    </Section2>
);

const ScenarioMismatchSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <MismatchSection
        irradiation_variance={scenario.irradiation_variance}
        temperature_variance={scenario.temperature_variance}
        min_module_binning={scenario.min_module_binning}
        max_module_binning={scenario.max_module_binning}
    />
);

const ComponentSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2
        title="Component Characterizations"
        subtitle="Define precisely which module characterizations (e.g. PAN/OND files) will be used during the simulation"
    >
        <ScenarioParameter label="Components">
            <CharacterizationTable scenario={scenario} />
        </ScenarioParameter>
    </Section2>
);

const ScenarioAdvancedSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <AdvancedSection
        transposition_model={scenario.transposition_model}
        use_project_location={scenario.use_project_location}
        use_spectral_adjustment={scenario.use_spectral_adjustment}
    />
);

const ScenarioTrackerSection: React.FC<{ scenario: scen.Scenario }> = ({ scenario }) => (
    <Section2 title="Trackers" subtitle="Options for tracker systems">
        <ScenarioParameter label="Maximum Angle" helperText="Maximum tilt angle for tracker systems">
            {scenario.tracker_max_angle}&deg;
        </ScenarioParameter>
        <ScenarioParameter label="Backtracking" helperText="Enables backtracking for tracker systems">
            {scenario.tracker_backtrack ? 'On' : 'Off'}
        </ScenarioParameter>
    </Section2>
);

const ParameterDisplay = ({ scenario, user }: { scenario: scen.Scenario; user: usr.User }) => (
    <>
        <MeteoSection scenario={scenario} />
        {user.hasP90WeatherVariabilityAccess() && <P90Section scenario={scenario} />}
        <HorizonShadingSection scenario={scenario} />
        <SoilingSection scenario={scenario} />
        <CellTemperatureSection scenario={scenario} />
        {user.hasFeature('enable_bifacial') && <BifacialSection scenario={scenario} />}
        <ScenarioMismatchSection scenario={scenario} />
        <ComponentSection scenario={scenario} />
        <ScenarioAdvancedSection scenario={scenario} />
        <AcLossesSection scenario={scenario} />
        {user.hasSingleAxisTrackersAccess() && <ScenarioTrackerSection scenario={scenario} />}
    </>
);

export { ParameterDisplay };
