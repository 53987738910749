import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import * as sub from 'reports/models/subscription';

import { Button } from 'reports/components/core/controls';

import {
    ControlContainer,
    ControlLabel,
    ControlPrimaryText,
    HelperText,
    SectionContainer,
} from 'reports/modules/settings/common';
import { promptCancel, cancelSubscriptionStr } from 'reports/modules/settings/billing/components/promptCancel';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const NoMarginButton = styled(Button)`
    margin-left: 0;
`;

const StyledControlLabel = styled(ControlLabel)`
    margin-right: 32px;
`;

interface Props {
    subscription: sub.Subscription;
}

const SelfServeCancellation = ({ subscription }: Props) => {
    const disabled = subscription.status !== 'active';
    const dispatch = useDispatch();
    const cancelAtPeriodEnd = (subscription) =>
        dispatch(sub.api.cancelAtPeriodEnd({ external_id: subscription.external_id }));
    return (
        <>
            <SectionContainer disabled={disabled}>
                <StyledControlLabel>
                    <ControlPrimaryText>Cancel subscription</ControlPrimaryText>
                    <HelperText>{cancelSubscriptionStr}.</HelperText>
                </StyledControlLabel>
                <ControlContainer>
                    <NoMarginButton
                        disabled={disabled}
                        text="Cancel"
                        intent={Intent.DANGER}
                        onClick={async () => await promptCancel({ subscription, cancelAtPeriodEnd })}
                        loadingWhileAwaiting={true}
                    />
                </ControlContainer>
            </SectionContainer>
        </>
    );
};

export default SelfServeCancellation;
