import React, { useState } from 'react';
import { Flex, Section2 } from 'reports/components/core/containers';
import { FormGroup } from 'reports/components/core/forms';
import { ControlLabel, ControlPrimaryText, HelperText } from 'reports/modules/settings/common';
import { FormPercentInput } from 'reports/components/forms/inputs/experimental';
import * as styles from 'reports/styles/styled-components';
import { FormField } from 'reports/components/forms';
import { Switch } from '@blueprintjs/core';
import ConditionSetMonthlyValuesEdit from 'reports/modules/condition_sets/components/ConditionSetMonthlyValuesEdit';
import { DEFAULT_SCENARIO_PARAMS } from 'reports/models/scenario';
import { Percent } from 'reports/components/core/numbers';
const styled = styles.styled;

export const ALBEDO_MONTHS = [
    {
        path: 'jan_albedo',
        monthName: 'Jan',
    },
    {
        path: 'feb_albedo',
        monthName: 'Feb',
    },
    {
        path: 'mar_albedo',
        monthName: 'Mar',
    },
    {
        path: 'apr_albedo',
        monthName: 'Apr',
    },
    {
        path: 'may_albedo',
        monthName: 'May',
    },
    {
        path: 'jun_albedo',
        monthName: 'Jun',
    },
    {
        path: 'jul_albedo',
        monthName: 'Jul',
    },
    {
        path: 'aug_albedo',
        monthName: 'Aug',
    },
    {
        path: 'sep_albedo',
        monthName: 'Sep',
    },
    {
        path: 'oct_albedo',
        monthName: 'Oct',
    },
    {
        path: 'nov_albedo',
        monthName: 'Nov',
    },
    {
        path: 'dec_albedo',
        monthName: 'Dec',
    },
];

const FlexContainer = styled(Flex.Container)`
    padding: 0 0 12px;
`;

const ControlContainer = styled.div`
    text-align: right;
`;

const FormSection = styled.div<{ disabled?: boolean }>`
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const StyledSwitch = styled(Switch)`
    margin-bottom: 0;
`;

const StyledHelperText = styled(HelperText)`
    padding: 0 0 12px;
`;

export const bifacialHelperText = {
    albedo: (
        <>
            Albedo is a decimal value between 0.0 and 1.0, representing the fraction of sunlight reflected off a
            surface. For instance, an albedo of 0.2 means 20% reflected. Higher albedo increases light reflected onto
            the backside of bifacial solar panels, boosting their energy efficiency. Examples of albedo values can be
            found <a href={''}>here</a>.
        </>
    ),
    rear_mismatch_loss_pct: (
        <span>
            Rear Mismatch Loss accounts for the non-uniform irradiance on the rear side of the module. Since voltage
            mismatch is not modeled directly, you’ll need to manually enter a value.
            <br />
            Note: The default is set to <Percent value={DEFAULT_SCENARIO_PARAMS.rear_mismatch_loss_pct / 100} />
        </span>
    ),
    rear_shading_factor_pct: (
        <span>
            Rear Shading Factor accounts for shading caused by mechanical structures behind the modules. Since these
            structures are not modeled directly, you'll need to enter a value manually.
            <br />
            Note: The default is set to <Percent value={DEFAULT_SCENARIO_PARAMS.rear_shading_factor_pct / 100} />
        </span>
    ),
    module_transparency: (
        <span>
            The percentage of light that passes through a bifacial module, its frame, or its racking structure. This
            light will cause additional ground irradiance, which can be reflected to the rear side and produce
            additional energy in a bifacial system.
            <br />
            Note: The default is set to <Percent value={DEFAULT_SCENARIO_PARAMS.module_transparency / 100} />
        </span>
    ),
};

const ConditionSetBifacialEdit = ({ scenario }) => {
    const [bifacialEnabled, setBifacialEnabled] = useState(scenario.bifacial_model_enabled);

    const onSwitchChange = (e, onChange) => {
        setBifacialEnabled((e.target as HTMLInputElement).checked);
        onChange((e.target as HTMLInputElement).checked);
    };

    return (
        <Section2
            title="Bifacial System"
            subtitle="Improve simulation accuracy by accounting for rear-side energy gains with bifacial modules. For more information about this feature visit our Help Center."
            id="bifacial"
        >
            <FlexContainer>
                <ControlContainer>
                    <FormField path="bifacial_model_enabled">
                        {({ onChange, value, form }) => (
                            <StyledSwitch
                                checked={value}
                                onChange={(e) => onSwitchChange(e, onChange)}
                                disabled={form.submitting}
                                large={true}
                            />
                        )}
                    </FormField>
                </ControlContainer>
                <ControlLabel>
                    <ControlPrimaryText>Enable Bifacial Model</ControlPrimaryText>
                    <HelperText>
                        Enable this feature to account for rear-side gains in simulations when using bifacial modules
                    </HelperText>
                </ControlLabel>
            </FlexContainer>
            <FormSection data-testid="form-section" disabled={!bifacialEnabled}>
                <FormGroup label="Albedo">
                    <StyledHelperText>{bifacialHelperText.albedo}</StyledHelperText>
                    <ConditionSetMonthlyValuesEdit
                        fieldMap={ALBEDO_MONTHS}
                        defaultAllValue={DEFAULT_SCENARIO_PARAMS.default_albedo}
                        isPercentage={false}
                        min={0}
                        max={1}
                    />
                </FormGroup>
                <FormPercentInput
                    path="rear_mismatch_loss_pct"
                    label="Rear Mismatch Loss"
                    placeholder="Rear Mismatch Loss"
                    helperText={bifacialHelperText.rear_mismatch_loss_pct}
                />
                <FormPercentInput
                    path="rear_shading_factor_pct"
                    label="Rear Shading Factor"
                    placeholder="Rear Shading Factor"
                    helperText={bifacialHelperText.rear_shading_factor_pct}
                />
                <FormPercentInput
                    path="module_transparency"
                    label="Module Transparency"
                    placeholder="Module Transparency"
                    helperText={bifacialHelperText.module_transparency}
                />
            </FormSection>
        </Section2>
    );
};

export default ConditionSetBifacialEdit;
