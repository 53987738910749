import * as React from 'react';
import { useSelector } from 'react-redux';

import BasicTable from 'reports/components/core/tables/BasicTable';
import { Power } from 'reports/components/core/numbers';
import { Module, CellTechnologyTypes, ModuleTypes } from 'reports/models/module';
import * as auth from 'reports/modules/auth';
import { fallback } from 'reports/utils/helpers';

type Props = {
    module: Module;
};

const ModuleSpecs = ({ module }: Props) => {
    const user = useSelector((state) => auth.selectors.getUser(state));
    return (
        <BasicTable tableTheme="specs" width="100%">
            <tbody>
                <tr>
                    <th>Name</th>
                    <td>{module.name}</td>
                </tr>
                <tr>
                    <th>Manufacturer</th>
                    <td>{module.manufacturer}</td>
                </tr>
                {user?.hasFeature('enable_bifacial') && (
                    <tr>
                        <th>Module Type</th>
                        <td>{ModuleTypes[module.module_type]}</td>
                    </tr>
                )}
                <tr>
                    <th>Power</th>
                    <td>
                        <Power value={module.power} />
                    </td>
                </tr>
                <tr>
                    <th>Technology</th>
                    <td>
                        {CellTechnologyTypes[module.cell_technology_name]} (
                        {module.series_cells * module.parallel_cells} cells)
                    </td>
                </tr>
                <tr>
                    <th>Source</th>
                    <td>{fallback(module.source, '-')}</td>
                </tr>
                <tr>
                    <th>Team</th>
                    <td>{fallback(module.team?.name, '-')}</td>
                </tr>
            </tbody>
        </BasicTable>
    );
};

export { ModuleSpecs };
