import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { injectIntl, IntlShape } from 'react-intl';
import Highcharts from 'highcharts';

import Translations from 'reports/localization/strings';
import * as fmt from 'reports/utils/formatters';
import * as proj from 'reports/models/project';
import { TOKEN_FUNCTIONS } from 'reports/modules/financials/model/tokens';
import * as finState from 'reports/modules/financials/state';
import { ChartType, ILayoutChartProps, LayoutChart } from 'reports/modules/report/components/charts';
import { Theme, DEFAULT_THEME } from 'reports/modules/themes';
import { IAppState } from 'reports/types';

interface IOwnProps extends ILayoutChartProps {
    project: proj.Project;
    financialTokens: finState.MaybeFinOutput;
    chartType?: ChartType;
    withSolar?: boolean;
    withoutSolar?: boolean;
    year?: number;
    theme?: Theme;
}

interface IOwnPropsIntl extends IOwnProps {
    intl: IntlShape;
}

interface ISeries {
    name: string;
    data: number[];
    type: string;
    color: any;
    visible?: boolean;
}

function makeConfig(series: ISeries[], intl: IntlShape): Highcharts.Options {
    const { locale } = intl;
    return {
        series,

        chart: {
            type: 'column',
        },
        title: {
            text: '',
        },
        xAxis: {
            categories: [
                intl.formatMessage(Translations.constants.january_abbrev),
                intl.formatMessage(Translations.constants.february_abbrev),
                intl.formatMessage(Translations.constants.march_abbrev),
                intl.formatMessage(Translations.constants.april_abbrev),
                intl.formatMessage(Translations.constants.may_abbrev),
                intl.formatMessage(Translations.constants.june_abbrev),
                intl.formatMessage(Translations.constants.july_abbrev),
                intl.formatMessage(Translations.constants.august_abbrev),
                intl.formatMessage(Translations.constants.september_abbrev),
                intl.formatMessage(Translations.constants.october_abbrev),
                intl.formatMessage(Translations.constants.november_abbrev),
                intl.formatMessage(Translations.constants.december_abbrev),
            ],
        },
        yAxis: {
            min: 0,
            title: {
                text: fmt.currencySymbol(),
            },
            labels: {
                formatter(this: any) {
                    return fmt.stringifyNumber(this.value, {
                        locale,
                        // @ts-ignore: notation option exists, but is not in our version of typescript.
                        notation: 'compact',
                    });
                },
            },
            visible: true,
        },
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                return `${this.x}: <b>${fmt.currency(this.y, {
                    locale,
                    precision: 1,
                })}</b>`;
            },
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            series: {
                animation: false,
            },
        },
    };
}

const mapStateToProps = () => {
    const chartSelector = createSelector(
        (_state, props: IOwnPropsIntl) => props.chartType || 'column',
        (_state, props: IOwnPropsIntl) => props.withSolar !== false,
        (_state, props: IOwnPropsIntl) => props.withoutSolar !== false,
        (_state, props: IOwnPropsIntl) => props.theme || DEFAULT_THEME,
        (_state, props: IOwnPropsIntl) => props.financialTokens,
        (_state, props: IOwnPropsIntl) => props.intl,
        (type, withSolar, withoutSolar, theme, finTokens, intl) => {
            const dataSeries: ISeries[] = [
                ...(withoutSolar
                    ? [
                          {
                              type,
                              data: finState.hasOutput(finTokens) ? TOKEN_FUNCTIONS.bill_monthly_sum(finTokens) : [],
                              name: intl.formatMessage(Translations.financial.without_solar),
                              color: theme.secondary_color,
                              visible: withoutSolar,
                          },
                      ]
                    : []),
                ...(withSolar
                    ? [
                          {
                              type,
                              data: finState.hasOutput(finTokens)
                                  ? TOKEN_FUNCTIONS.bill_monthly_sum_with_solar(finTokens)
                                  : [],
                              name: intl.formatMessage(Translations.financial.with_solar),
                              color: theme.primary_color,
                              visible: withSolar,
                          },
                      ]
                    : []),
            ];

            return makeConfig(dataSeries, intl);
        },
    );

    return (state: IAppState, ownProps: IOwnPropsIntl) => {
        return {
            config: chartSelector(state, ownProps),
        };
    };
};

export const MonthlySavingsChart: React.ComponentClass<IOwnProps> = injectIntl(
    connect(mapStateToProps)(LayoutChart as any) as any,
) as any;

export default MonthlySavingsChart;
