/*
 * Generic Select built off of BlueprintJS's Select component
 */
import * as React from 'react';
import { debounce, isFunction } from 'lodash';

import {
    Button,
    Classes,
    IconSize,
    InputGroupProps2,
    Intent,
    Menu,
    MenuDivider,
    MenuItem,
    Spinner,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ItemRenderer, ItemListRenderer, Select } from '@blueprintjs/select';

import { isSubstr } from 'reports/utils/strings';
import { Ellipsis } from 'reports/components/helpers/common';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const MIN_QUERY_LENGTH = 3;
const DEBOUNCE_INTERVAL = 200;

export const StyledMenu = styled(Menu)<{ $matchSelectWidth?: boolean }>`
    ${({ $matchSelectWidth }) => ($matchSelectWidth ? '' : 'width: 400px')};
`;

// $-prefixed props are "transient" and aren't passed to underlying in styled-components
const StyledButton = styled(Button)<{ $maxButtonWidth?: number }>`
    ${(props) => (props.$maxButtonWidth ? `max-width: ${props.$maxButtonWidth}px;` : '')}
    .${Classes.BUTTON_TEXT} {
        width: calc(100% - ${IconSize.STANDARD}px);
    }
`;

interface IItemProps {
    key: string | number;
    text: React.ReactNode;
    // Right now, selectOption does the same thing as "text" and is only used in one place. Can we get rid of it?
    selectOption?: React.ReactNode;

    label?: string;
    labelElement?: React.ReactNode;
    disabled?: boolean;
}

interface IBasicSelect<T> {
    itemRenderer: (item: T) => IItemProps;

    value: T | null;
    onChange: (item: T, event?: React.SyntheticEvent<HTMLElement>) => any;

    actionButton?: React.ReactNode;
    minimal?: boolean;
    noResults?: React.ReactNode;
    noneSelected?: React.ReactNode;
    disabled?: boolean;
    fill?: boolean;
    matchSelectWidth?: boolean;
    maxButtonWidth?: number;
    rightAligned?: boolean;
    dataSource: ISyncDataSource<T> | IAsyncDataSource<T>;
}
export interface ISyncDataSource<T> {
    async?: false;
    items: T[] | Promise<T[]> | (() => Promise<T[]>);
    filterBy?: keyof T; // e.g. "name" or "description"
}

export interface IAsyncDataSource<T> {
    async: true;
    query: (key: string) => Promise<T[]>;
}

export type BasicSelectProps<T = any> = IBasicSelect<T>;

export const BasicSelect = <SelectType,>(props: BasicSelectProps<SelectType>) => {
    const {
        value,
        onChange,
        actionButton,
        minimal,
        noResults,
        noneSelected,
        disabled,
        fill,
        matchSelectWidth,
        maxButtonWidth,
        rightAligned,
        dataSource,
    } = props;
    const [loadedItems, setLoadedItems] = React.useState<SelectType[]>([]);
    const [initialItems, setInitialItems] = React.useState<SelectType[]>([]);
    const [isLoaded, setIsLoaded] = React.useState<boolean>(false);
    const [errorState, setErrorState] = React.useState<boolean>(false);
    const [shortQueryState, setShortQueryState] = React.useState<boolean>(false);
    const [isInitialized, setIsInitialized] = React.useState<boolean>(!!dataSource.async);
    const [prevQuery, setPrevQuery] = React.useState<string>('');
    const [renderStale, setRenderStale] = React.useState<boolean>(false);

    const loadResult = (result) => {
        setLoadedItems(result);
        setIsLoaded(true);
        setErrorState(false);
        setRenderStale(true);
    };
    const initializeResult = (result) => {
        loadResult(result);
        setInitialItems(result);
        setIsInitialized(true);
    };

    const filterBy = dataSource.async ? undefined : dataSource.filterBy;
    const filterable = dataSource.async || (!!filterBy && loadedItems.length > 5);
    const itemPredicate = filterBy
        ? (key: string, item: SelectType) => isSubstr(item[filterBy] as any, key)
        : undefined;
    const itemRenderer = (item: SelectType | null) => item && props.itemRenderer(item!);

    let onQueryChange = undefined as any;
    if (dataSource.async) {
        const { query } = dataSource;
        const debouncedSearch = React.useCallback(
            debounce(
                (queryString) => query(queryString).then(loadResult, (_err) => setErrorState(true)),
                DEBOUNCE_INTERVAL,
                { trailing: true },
            ),
            [query],
        );
        React.useEffect(() => {
            query('').then(initializeResult, (_err) => setErrorState(true));
        }, []);
        onQueryChange = (queryString: string, _event?: React.ChangeEvent<HTMLInputElement>) => {
            if (queryString.length >= MIN_QUERY_LENGTH) {
                setIsLoaded(false);
                setErrorState(false);
                setShortQueryState(false);
                debouncedSearch(queryString);
            } else if (queryString.length === 0) {
                setShortQueryState(false);
                loadResult(initialItems);
            } else {
                setShortQueryState(true);
            }
            if (prevQuery.length < MIN_QUERY_LENGTH && prevQuery.length > 0 && queryString.length !== 0) {
                setRenderStale(false);
            }
            setPrevQuery(queryString);
        };
    } else {
        const { items } = dataSource;
        React.useEffect(() => {
            if (Array.isArray(items)) {
                initializeResult(items);
            } else if (isFunction(items)) {
                items().then(initializeResult, console.error);
            } else {
                (items as any).then(initializeResult, console.error);
            }
        }, [items]);
    }

    const renderItem: ItemRenderer<SelectType> = (item, { handleClick, modifiers }) => {
        const { matchesPredicate, ...otherModifiers } = modifiers;
        if (!matchesPredicate) {
            return null;
        }
        const dropdownItem = itemRenderer(item)!;
        return (
            <MenuItem
                key={dropdownItem.key}
                text={dropdownItem.selectOption || dropdownItem.text}
                onClick={dropdownItem.key === itemRenderer(value)?.key ? undefined : handleClick}
                label={dropdownItem.label}
                labelElement={dropdownItem.labelElement}
                {...otherModifiers}
            />
        );
    };

    const renderMenu: ItemListRenderer<SelectType> = ({ filteredItems, itemsParentRef, renderItem }) => {
        const renderedItems = filteredItems.map(renderItem);
        return (
            <StyledMenu $matchSelectWidth={matchSelectWidth} ulRef={itemsParentRef}>
                {shortQueryState ? (
                    <div>Please type at least {MIN_QUERY_LENGTH} characters.</div>
                ) : !renderStale ? (
                    <div>Searching...</div>
                ) : renderedItems.length === 0 ? (
                    noResults || <MenuItem text="No results." disabled={true} />
                ) : (
                    renderedItems
                )}
                {actionButton && <MenuDivider />}
                {actionButton}
            </StyledMenu>
        );
    };

    const TypedSelect = Select.ofType<SelectType>();

    const leftInputProps: InputGroupProps2 = errorState
        ? {
              leftIcon: IconNames.OUTDATED,
              intent: Intent.DANGER,
          }
        : isLoaded
        ? {}
        : {
              leftElement: <Spinner size={16} className={Classes.ICON} />,
          };

    const inputProps = {
        ...leftInputProps,
        placeholder: 'Search...',
    };

    return (
        <TypedSelect
            items={loadedItems}
            activeItem={value}
            onItemSelect={onChange}
            filterable={filterable}
            disabled={disabled}
            itemRenderer={renderItem}
            itemListRenderer={renderMenu}
            itemsEqual={(itemA, itemB) => itemRenderer(itemA)?.key === itemRenderer(itemB)?.key}
            itemPredicate={itemPredicate}
            onQueryChange={onQueryChange}
            noResults={noResults}
            inputProps={inputProps}
            popoverProps={{
                fill,
                minimal: true,
                placement: rightAligned ? 'bottom-end' : 'bottom-start',
                position: undefined, // removes popover warning
            }}
        >
            <StyledButton
                fill={fill}
                minimal={minimal}
                text={<Ellipsis>{itemRenderer(value)?.text || (isInitialized ? noneSelected : ' ')}</Ellipsis>}
                alignText="left"
                rightIcon={!minimal ? IconNames.CARET_DOWN : IconNames.CHEVRON_DOWN}
                disabled={disabled}
                loading={!isInitialized}
                $maxButtonWidth={maxButtonWidth}
            />
        </TypedSelect>
    );
};

export default BasicSelect;
