import * as React from 'react';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';

import { IconNames } from '@blueprintjs/icons';

import Translations from 'reports/localization/strings';
import { WIDGET_BORDER_RADIUS } from 'reports/modules/report/widget_style';

import { IWidgetRenderProps, IReportContext, registerWidget } from 'reports/modules/report/widgets';
import { Scenario } from 'reports/models/scenario';
import { WidgetDataTable } from 'reports/modules/report/components/helpers';

import * as styles from 'reports/styles/styled-components';
import { ProbabilityDistributionConfig } from 'reports/modules/project/components/condition_sets/ConditionSetP90Edit';
import { Percent } from 'reports/components/core/numbers';
import { p90UserHasAccess } from './P90Chart';
const styled = styles.styled;

const CombinedTableContainer = styled.div`
    &.widget-header {
        table {
            border: none;

            tr > :first-child {
                border-left: none;
            }
            tr > :last-child {
                border-right: none;
            }
        }
        table:first-child {
            thead tr:first-child > * {
                box-shadow: none;
                border-top: none;
            }
        }
        table:last-child {
            border-radius: ${WIDGET_BORDER_RADIUS}px;

            tbody tr:last-child > * {
                border-bottom: none;
            }
            tbody tr:last-child > :first-child {
                border-bottom-left-radius: ${WIDGET_BORDER_RADIUS}px;
            }
            tbody tr:last-child > :last-child {
                border-bottom-right-radius: ${WIDGET_BORDER_RADIUS}px;
            }
        }
    }
`;

interface WeatherVariabilityProps {
    scenario: Scenario;
    intl: IntlShape;
}

const WeatherVariabilityIntl = ({ scenario }: WeatherVariabilityProps) => {
    const { annual_weather_variability, annual_weather_variability_type } = scenario;

    let weatherSourceLabel = scenario.weather_dataset.weather_source.toString();

    weatherSourceLabel =
        annual_weather_variability_type === 'custom'
            ? 'User-defined'
            : `${weatherSourceLabel} - ${annual_weather_variability_type}`;

    return (
        <>
            <tr>
                <th colSpan={2}>
                    <FormattedMessage {...Translations.p90.weather_variability_header} />
                </th>
            </tr>
            <tr>
                <td className="text-cell">
                    <FormattedMessage {...Translations.p90.weather_variability_datasource} />
                </td>
                <td className="text-cell">{weatherSourceLabel}</td>
            </tr>
            <tr>
                <td className="text-cell">
                    <FormattedMessage {...Translations.p90.annual_weather_variability} />
                </td>
                <td>
                    <Percent value={annual_weather_variability / 100} />
                </td>
            </tr>
        </>
    );
};

const WeatherVariability = injectIntl(WeatherVariabilityIntl);

interface SystemVariabilityProps {
    probDistConfig: ProbabilityDistributionConfig;
    intl: IntlShape;
}

const SystemVariabilityIntl = ({ probDistConfig }: SystemVariabilityProps) => {
    const SYSTEM_VARIABILITY_KEYS = [
        'pv_module_modeling_parameters',
        'inverter_efficiency',
        'soiling_mismatch',
        'degradation_estimation',
        'custom_variability',
    ];

    return (
        <>
            <tr>
                <th colSpan={2}>
                    <FormattedMessage {...Translations.p90.system_variability_header} />
                </th>
            </tr>
            {SYSTEM_VARIABILITY_KEYS.map((key) => {
                return (
                    <tr key={key}>
                        <td className="text-cell">
                            <FormattedMessage {...Translations.p90[key]} />
                        </td>
                        <td>
                            <Percent value={probDistConfig.system_variability[key] / 100} />
                        </td>
                    </tr>
                );
            })}
        </>
    );
};

const SystemVariability = injectIntl(SystemVariabilityIntl);

interface TotalVariabilityProps {
    probDistConfig: ProbabilityDistributionConfig;
    intl: IntlShape;
}

const TotalVariabilityIntl = ({ probDistConfig }: TotalVariabilityProps) => {
    return (
        <>
            <tr>
                <th>
                    <FormattedMessage {...Translations.p90.total_variability_header} />
                </th>
                <td>
                    <Percent value={probDistConfig.total_variability / 100} />
                </td>
            </tr>
        </>
    );
};

const TotalVariability = injectIntl(TotalVariabilityIntl);

export interface P90ParametersTableProps {
    scenario: Scenario;
    className?: string;
}

export const P90ParametersTable = ({ scenario, className }: P90ParametersTableProps) => {
    const { probability_distribution_config: probDistConfig } = scenario;

    return (
        <CombinedTableContainer className={className}>
            <WidgetDataTable>
                <tbody>
                    <WeatherVariability scenario={scenario} />
                    <SystemVariability probDistConfig={probDistConfig} />
                    <TotalVariability probDistConfig={probDistConfig} />
                </tbody>
            </WidgetDataTable>
        </CombinedTableContainer>
    );
};

type IContext = Pick<IReportContext, 'scenario'>;
type IProps = IWidgetRenderProps<object, IContext>;

const _P90ParametersTable = ({ context, className }: IProps) => {
    const { scenario } = context;

    return <P90ParametersTable scenario={scenario} className={className} />;
};

export const P90ParametersTableWidget = registerWidget('p90_parameters_table', {
    Component: _P90ParametersTable,
    metadata: {
        category: 'project',
        dimensions: { h: 750, w: 800 },
        displayName: Translations.widgets.p90_parameters_table_header,
        icon: IconNames.TH,
        userHasAccess: p90UserHasAccess,
    },
    dependencies: ['scenario', 'probabilityConfig'],
});

export default P90ParametersTableWidget;
