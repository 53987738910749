import * as React from 'react';

import * as fmt from 'reports/utils/formatters';

import Section2 from 'reports/components/core/containers/Section2';
import {
    FormBareNumericInput,
    FormNumericInput,
    FormBasicSelect,
    FormCheckboxInput,
    FormPercentInput,
    fromDict,
    IItem,
} from 'reports/components/forms/inputs/experimental';
import ConditionSetCellTempEdit from 'reports/modules/condition_sets/components/ConditionSetCellTempEdit';
import ConditionSetSoilingEdit from 'reports/modules/condition_sets/components/ConditionSetSoilingEdit';
import ConditionSetBifacialEdit from 'reports/modules/condition_sets/components/ConditionSetBifacialEdit';

// Cell Temp Edit Fields
export const CellTempModelSelect = ({ path, helperText, label }) => (
    <FormBasicSelect<IItem<string>>
        path={path}
        helperText={helperText}
        label={label}
        dataSource={{
            items: fromDict(fmt.CELL_TEMPERATURE_MODEL_NAMES),
            keyLookup: ({ key }) => key,
        }}
        itemRenderer={({ key, name }) => ({ key, text: name })}
    />
);

export const DiffuseRowFields = () => (
    <>
        <td>
            <FormBareNumericInput path="u_const" placeholder="Constant Thermal Loss Factor (Uc)" min={0} max={50} />
        </td>
        <td>
            <FormBareNumericInput path="u_wind" placeholder="Variable Thermal Loss Factor (Uwind)" min={0} max={50} />
        </td>
    </>
);

export const SandiaRowFields = () => (
    <>
        <td>
            <FormBareNumericInput path="a" placeholder="Constant Thermal Loss Factor (a)" min={-20} max={0} />
        </td>
        <td>
            <FormBareNumericInput path="b" placeholder="Wind Thermal Loss Factor (b)" min={-5} max={0} />
        </td>
        <td>
            <FormBareNumericInput
                path="delta_temperature"
                placeholder="Difference between module and cell temperature"
                min={0}
                max={20}
            />
        </td>
    </>
);

// Shared Mismatch Component
const ConditionSetMismatchEdit: React.FC = () => (
    <Section2
        title="Mismatch"
        subtitle="Statistical distributions applied module-by-module used to define module binning and other mismatch parameters"
    >
        {/* TODO: find clean way to doing mult/div by 100 */}
        {/* TODO: extract this formatted input from where I copied it */}

        {/* TODO: min 0, max 50 */}
        <FormPercentInput
            path="irradiation_variance"
            label="Irradiation Variance"
            placeholder="Irradiation Variance"
            helperText="Standard deviation around expected irradiance (normal distribution)"
        />
        <FormNumericInput
            path="temperature_variance"
            label="Temperature Spread (&deg;C)"
            placeholder="Temperature Spread (&deg;C)"
            helperText="Total degree spread centered around the modeled cell temperature (uniform distribution)"
            min={0}
            max={25}
            width="100%"
        />
        {/* TODO: satisfy range (min must be less than max) */}
        {/* TODO: min -50, max 50 */}
        <FormPercentInput
            path="min_module_binning"
            label="Min. Module Tolerance"
            placeholder="Min. Module Tolerance"
            helperText="Minimum deviance from specific maximum power point (uniform distribution)"
        />
        {/* TODO: min -50, max 50 */}
        <FormPercentInput
            path="max_module_binning"
            label="Max. Module Tolerance"
            placeholder="Max. Module Tolerance"
            helperText="Maximum deviance from specific maximum power point (uniform distribution)"
        />
    </Section2>
);

// Shared AC Losses Component
const ConditionSetAcLossesEdit: React.FC = () => (
    <Section2 title="AC Losses" subtitle="Derates to system power for AC conductors and transformers">
        {/* TODO: min 0, max 50 */}
        <FormPercentInput
            path="ac_conductor_derate"
            label="AC System Derate"
            placeholder="AC System Derate"
            helperText="Constant derate factor to compensate for AC system losses (conductors, transformers, etc)
                that is applied to the inverter's AC Output within the loss tree.  This will only be used
                if you do not define a detailed AC subsystem"
        />
    </Section2>
);

// Shared Advanced Section Components
const TranspositionModelSelect = ({ path, helperText, label }) => (
    <FormBasicSelect<IItem<string>>
        path={path}
        helperText={helperText}
        label={label}
        dataSource={{
            items: fromDict(fmt.TRANSPOSITION_MODEL_NAMES),
            keyLookup: ({ key }) => key,
        }}
        itemRenderer={({ key, name }) => ({ key, text: name })}
    />
);

interface IProjectLocationType {
    name: string;
    key: boolean;
}

const PROJECT_LOCATION_TYPES: IProjectLocationType[] = [
    {
        name: 'Meteo File Lat/Lng',
        key: false,
    },
    {
        name: 'Project Lat/Lng',
        key: true,
    },
];

const UseProjectLocationSelect = ({ path, helperText, label }) => (
    <FormBasicSelect<IProjectLocationType>
        path={path}
        helperText={helperText}
        label={label}
        dataSource={{
            items: PROJECT_LOCATION_TYPES,
            keyLookup: ({ key }) => key,
        }}
        itemRenderer={({ key, name }) => ({ key: String(key), text: name })}
    />
);

const ConditionSetAdvancedEdit: React.FC = () => (
    <Section2 title="Advanced" subtitle="Other settings used in the simulation of your design">
        <TranspositionModelSelect
            path="transposition_model"
            label="Transposition Model"
            helperText="The model used to convert horizontal diffuse to POA diffuse irradiance"
        />
        <UseProjectLocationSelect
            path="use_project_location"
            label="Solar Angle Location"
            helperText="The location used for calculating solar angles"
        />
        {/* TODO: Figure out if we can exclude when no CdTe present --
                might need rewrite of hasSpectral or new function */}
        <FormCheckboxInput
            path="use_spectral_adjustment"
            label="Spectral Adjustments"
            checkboxLabel="Enables spectral adjustments for GHI (CdTe cells only)"
        />
    </Section2>
);

// Shared Tracker Section Components
const ConditionSetTrackerEdit: React.FC<{}> = () => (
    <Section2 title="Trackers" subtitle="Options for tracker systems">
        <FormNumericInput
            path="tracker_max_angle"
            label="Maximum Angle (&deg;)"
            placeholder="Maximum Angle (&deg;)"
            helperText="Maximum tilt angle for tracker systems"
            bold
            min={0}
            max={90}
        />
        <FormCheckboxInput
            path="tracker_backtrack"
            label="Backtracking"
            checkboxLabel="Enables backtracking for tracker systems"
        />
    </Section2>
);

export {
    ConditionSetSoilingEdit,
    ConditionSetBifacialEdit,
    ConditionSetCellTempEdit,
    ConditionSetMismatchEdit,
    ConditionSetAcLossesEdit,
    ConditionSetAdvancedEdit,
    ConditionSetTrackerEdit,
};
