/*
 * Generic Select built off of BlueprintJS's Select component
 */
import * as React from 'react';

import { IButtonProps, IconName, MaybeElement, Menu, MenuItem, PopoverPosition } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { ICreateNewItem, ISelectProps, ItemListRenderer, Select } from '@blueprintjs/select';

import { BehavedButton } from 'reports/components/helpers/common';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const ConstrainedMenu = styled(Menu)`
    max-height: 300px;
    overflow: auto;
`;

export interface IStaticSelect<T = any> extends ISelectProps<T> {
    buttonText: string | React.ReactNode;
    buttonProps?: IButtonProps & { style?: React.CSSProperties };
    fill?: boolean;
    icon?: IconName | MaybeElement;
}

interface IState<T> {
    activeItem?: T | null | ICreateNewItem;
}

/**
 * @deprecated Use BasicSelect instead. StaticSelect has bugs, a worse API, and is not being maintained.
 */
export class StaticSelect<SelectType = any> extends React.PureComponent<IStaticSelect<SelectType>, IState<SelectType>> {
    state: IState<SelectType> = {
        activeItem: this.props.activeItem,
    };

    componentDidUpdate(prevProps) {
        const { activeItem } = this.props;

        if (prevProps.activeItem !== activeItem && activeItem !== undefined) {
            this.setState({ activeItem });
        }
    }

    render() {
        const TypedSelect = Select.ofType<SelectType>();

        const {
            buttonText,
            icon,
            items,
            fill,
            buttonProps,
            popoverProps,
            disabled,
            activeItem: _activeItem,
            noResults: _noResults,
            ...otherProps
        } = this.props;

        return (
            <TypedSelect
                items={items}
                itemListRenderer={this.renderItemList}
                popoverProps={{
                    boundary: 'scrollParent',
                    modifiers: { preventOverflow: { enabled: true } },
                    popoverClassName: 'select-popover', // add class to style portal popovers (`app.scss`)
                    position: PopoverPosition.BOTTOM_RIGHT,
                    targetProps: { style: { width: '100%' } },
                    minimal: true,
                    onClose: () => this.setActiveItem(this.props.activeItem),
                    ...popoverProps,
                }}
                // Controlled active item
                activeItem={this.state.activeItem}
                onActiveItemChange={this.setActiveItem}
                filterable={false}
                disabled={disabled}
                {...otherProps}
            >
                <BehavedButton
                    fill={fill}
                    text={buttonText}
                    icon={icon || false}
                    rightIcon={IconNames.CARET_DOWN}
                    disabled={disabled}
                    {...buttonProps}
                />
            </TypedSelect>
        );
    }

    renderItemList: ItemListRenderer<SelectType> = ({ items, itemsParentRef, renderItem, query, filteredItems }) => {
        const currItems = query.length ? filteredItems : items;
        const renderedItems = currItems.map(renderItem).filter((item) => item != null);

        return (
            <ConstrainedMenu ulRef={itemsParentRef}>
                {currItems.length
                    ? renderedItems
                    : this.props.noResults || <MenuItem text="No results." disabled={true} />}
            </ConstrainedMenu>
        );
    };

    setActiveItem = (activeItem) => this.setState({ activeItem });
}

export default StaticSelect;
