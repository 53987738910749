import { DEFAULT_CURRENCY_USD } from 'reports/localization';
import * as sub from 'reports/models/subscription';
import { Price } from 'reports/models/stripe/price';
import { currency } from 'reports/utils/formatters';

import { RequiredFieldError } from 'reports/modules/settings/common';
import { User } from '@sentry/browser';

export class StripeCardSetupError extends Error {
    errors?: object;
    code?: string;

    constructor(message, errors, code = '') {
        super(message);
        this.name = 'StripeCardSetupError';
        this.errors = errors;
        this.code = code;
    }
}

export function formatBillingCurrency(value: number, precision: number = 2) {
    return currency(value, {
        precision,
        currency: DEFAULT_CURRENCY_USD,
        isCents: true,
    });
}

export const billingTabEnabled = (subscription: sub.Subscription | undefined) => {
    return subscription != null;
};

export const getPriceFromProduct = (interval: string, product: string, prices: Price[]) =>
    prices.filter((price) => price.recurring.interval === interval && price.product.id === product).pop();

export const getPrice = (prices: Price[], interval: string): Price | undefined =>
    prices.filter((price) => price.recurring.interval === interval).pop();

export const hasPrice = (prices: Price[], price: Price | undefined) =>
    !!prices.filter((prc) => prc.id === price?.id).length;

export const isDowngrade = (
    user: User,
    proPrices: Price[] | undefined,
    basicPrices: Price[] | undefined,
    price: Price | undefined,
) => {
    if (
        proPrices &&
        basicPrices &&
        price &&
        hasPrice(proPrices, user.subscription?.price) &&
        hasPrice(basicPrices, price)
    ) {
        // Special clause: Pro Monthly to Basic Annual is NOT considered downgrade and we prorate accordingly!
        return !(user.subscription?.price.recurring.interval === 'month' && price.recurring.interval === 'year');
    }
    return false;
};

// Used to check for any downgrades that would be immediate changes instead of scheduled, these are billed immediately and prorated
// e.g. Pro Monthly to Basic Annual
export const isImmediateDowngrade = (
    user: User,
    proPrices: Price[] | undefined,
    basicPrices: Price[] | undefined,
    price: Price | undefined,
) =>
    proPrices &&
    basicPrices &&
    price &&
    hasPrice(proPrices, user.subscription?.price) &&
    hasPrice(basicPrices, price) &&
    user.subscription?.price.recurring.interval === 'month' &&
    price.recurring.interval === 'year';

export const isSeatRemoval = (user: User, quantity: number | undefined) =>
    quantity && user.subscription && user.subscription?.paid_seats > quantity;

export const handleFormException = (exc) => {
    if (exc instanceof RequiredFieldError) {
        return {
            formErrors: [],
            fieldErrors: exc.errors as Partial<{
                email: string[];
                billingDetails: string[];
            }>,
        };
    }

    if (exc instanceof StripeCardSetupError) {
        return {
            formErrors: [`${exc.code ? '' : 'Billing error: '}${exc.message}`],
            fieldErrors: exc.errors as Partial<{
                card: string[];
            }>,
        };
    }
    if (exc.response) {
        const { error: formError = null, ...fieldErrors } = exc.response.body;
        return {
            fieldErrors,
            formErrors: formError ? [formError] : [],
        };
    }

    // fallthrough with some generic error messaging if we get an unexpected exception
    console.error('Unhandled form exception', exc);
    return {
        fieldErrors: [],
        formErrors: ['An error occurred, please try again later'],
    };
};
