import * as React from 'react';
import Highcharts from 'highcharts';

import { Colors as bpColors } from '@blueprintjs/core';

import * as fmt from 'reports/utils/formatters';

import { LayoutChart } from 'reports/modules/report/components/charts';

function makeConfig(consumption): Highcharts.Options {
    const monthlyData = consumption.dailyProfileKWh();

    const series = monthlyData.map((data, i) => ({
        data,
        name: fmt.month(i),
        marker: { symbol: 'circle' },
    }));

    return {
        series,
        chart: {
            type: 'spline',
            backgroundColor: 'transparent',
        },
        title: { text: '' },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%k:%M',
            },
        },
        yAxis: {
            min: 0,
            title: {
                text: 'kWh',
            },
            labels: {
                formatter(this: any) {
                    return fmt.stringifyNumber(this.value, {
                        // @ts-ignore: notation option exists, but is not in our version of typescript.
                        notation: 'compact',
                    });
                },
            },
            visible: true,
        },
        legend: {
            enabled: true,
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            formatter(this: any) {
                return (
                    `<strong>${fmt.hourOfDay(this.points[0].x)}</strong><br>` +
                    this.points
                        .map((point) => {
                            // y-axis is in kWh. multiply by 1000 to convert to Wh.
                            const val = fmt.humanizeEnergy(point.y * 1000, {
                                precision: 1,
                            });
                            return `<span>${point.series.name}: <strong>${val}</strong></span>`;
                        })
                        .join('<br>')
                );
            },
            shared: true,
            useHTML: true,
        },
        plotOptions: {
            series: {
                animation: false,
            },
        },
        // Highcharts has only 10 colors by default
        colors: [
            bpColors.BLUE5,
            bpColors.GREEN3,
            bpColors.VERMILION5,
            bpColors.ROSE3,
            bpColors.VIOLET5,
            bpColors.INDIGO3,
            bpColors.COBALT5,
            bpColors.TURQUOISE3,
            bpColors.FOREST5,
            bpColors.LIME3,
            bpColors.GOLD5,
            bpColors.SEPIA3,
        ],
    };
}

/**
 * Displays hourly averages for all 12 months
 * given a year of consumption data
 */
export const DailyProfileChart = ({ consumption }) => <LayoutChart config={makeConfig(consumption)} />;

export default DailyProfileChart;
